import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/api-service';

@Component({
  selector: "app-mat-dialog",
  templateUrl: "./mat-dialog.component.html",
  styleUrls: ["./mat-dialog.component.scss"]
})

export class MatDialogComponent implements OnInit {

  confirmButtonText = '';
  cancelButtonText = '';
  filterList;
  filterForm: FormGroup;
  normForm: FormGroup;
  filterApplyList;
  normfilterApplyList;
  fieldDisable: boolean;
  labelValue;
  sampleTypeDisable: boolean;
  screenName;
  dashboard2Flag: boolean;
  filterSampleType = new FormControl('');
  sampleTypefilterValue;
  internalfilterApplyList = [];
  modalName;
  NormList;
  normFilterValue = [];
  normFilter;
  behaviour;
  formFilterValue = [];
  filterBylabelValue;
  filterApplyForm = [];
  filterSampleTypeList = [{
    label: 'Gen Pop',
    value: 1
  },
  {
    label: 'Overall Sample',
    value: 2
  }]

  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<MatDialogComponent>,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private router: Router) {
    if (data) {
      this.screenName = data.dashboarName;
      this.modalName = data.modalName;
      this.behaviour = data.behaviourValue;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok;
        this.cancelButtonText = data.buttonText.cancel;
      }
    }
  }

  ngOnInit() {

    this.normForm = this.formBuilder.group({});
    this.filterForm = this.formBuilder.group({});

    if (this.screenName === "DB1") {
      this.apiService.getFilterDetails(this.screenName).subscribe(
        (filterRes) => {
          this.filterList = filterRes;
          for (let k = 0; k < this.filterList.length; k++) {
            this.filterForm.addControl(this.filterList[k].attributeID, new FormControl([]));
          }
          this.dashboard2Flag = false;
          if (localStorage.getItem('overSampleType') === "disable") {
            this.sampleTypeDisable = true;
            this.filterForm.get('Q1')?.setValue([]);
          } else {
            this.filterForm.get('Q1')?.setValue(['1']);
          }
        });
    } else if (this.screenName === "DB2") {
      this.dashboard2Flag = true;
      this.filterSampleType.setValue([1]);
    } else if (this.screenName === "DB3") {
      this.apiService.getFilterDetails(this.screenName).subscribe(
        (filterRes) => {
          this.filterList = filterRes;
          this.dashboard2Flag = false;
          for (let k = 0; k < this.filterList.length; k++) {
            this.filterForm.addControl(this.filterList[k].attributeID, new FormControl(''));
          }
          if (localStorage.getItem('overSampleType') === "disable") {
            this.sampleTypeDisable = true;
            this.filterForm.get('Q1')?.setValue([]);
          } else {
            this.filterForm.get('Q1')?.setValue(['1']);
          }
        });
    } else if (this.screenName === "DB5") {
      if (this.modalName === "NormFilter") {
        this.apiService.getInternalNormDetails("DB5").subscribe(
          (internalRes) => {
            if (internalRes) {
              this.NormList = internalRes.NormFilter;
              for (let k = 0; k < this.NormList.length; k++) {
                this.normForm.addControl(this.NormList[k].attributeID, new FormControl(''));
              }
            }
          });
      } else if (this.modalName === "AdFilter") {
        this.apiService.getFilterDetails(this.screenName).subscribe(
          (filterRes) => {
            this.filterList = filterRes;
            for (let k = 0; k < this.filterList.length; k++) {
              this.filterForm.addControl(this.filterList[k].attributeID, new FormControl([]));
            }
            this.dashboard2Flag = false;
            if (localStorage.getItem('overSampleType') === "disable") {
              this.sampleTypeDisable = true;
              this.filterForm.get('Q1')?.setValue([]);
            } else {
              this.filterForm.get('Q1')?.setValue(['1']);
            }
          });
      }
    }
  }

  onFormValueChange(event, data) {

    let formValue = Object.values(this.filterForm.value);
    let formKeyValue = Object.keys(this.filterForm.value);
    let filterValue = [];
    let filterLabelValue;
    let attributelabelValue;

    for (let i = 0; i < formValue.length; i++) {
      for (let j = 0; j < formKeyValue.length; j++) {
        if (i === j) {
          this.filterList.filter(
            (x) => {
              if (x.attributeID.toLowerCase().replace(/ +/g, "") === formKeyValue[j].toLowerCase()) {
                for (let r = 0; r < x.filterValues.length; r++) {
                  if (data.filterType === "SingleSelect") {
                    if (x.filterValues[r].filterId === event.value) {
                      filterLabelValue += x.filterValues[r].attribute_Description + ", ";
                    }
                  } else {
                    for (let t = 0; t < formValue[i]['length']; t++) {
                      if (x.filterValues[r].filterId === formValue[i][t]) {
                        filterLabelValue += x.filterValues[r].attribute_Description + ", ";
                      }
                    }
                  }
                }
              }
            });
        }
      }
    }
    this.labelValue = filterLabelValue;
    formValue.forEach(element => {
      if (element != "") {
        filterValue.push(element);
      }
    });

    if (filterValue.length > 3) {
      this.fieldDisable = true;
    }
  }

  onNormFilterValueChange(event, data) {
    this.normFilterValue.push({
      currentValue: event.value,
      data: data
    });
  }

  onConfirmClick(): void {
    if (this.screenName === "DB1") {
      this.apiService.onFilterDetails(this.labelValue?.slice(0, -2));
      let adObj = JSON.parse(localStorage.getItem('adDetails'));
      let formValue = Object.entries(this.filterForm.value);
      let filterFormList = [];

      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          this.filterList.filter(
            (x) => {
              if (x.attributeID.toLowerCase().replace(/ +/g, "") === formValue[i][0].toLowerCase()) {
                for (let n = 0; n < x.filterValues.length; n++) {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterValues[n].filterId === formValue[i][1]) {
                      filterFormList.push({
                        "filterName": x.filterValues[n].attributeID,
                        "filterType": x.filterType,
                        "filterVal": x.filterValues[n].attribute_Value.toString()
                      })
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterValues[n].filterId === formValue[i][1][k]) {
                        filterFormList.push({
                          "filterName": x.filterValues[n].attributeID,
                          "filterType": x.filterType,
                          "filterVal": x.filterValues[n].attribute_Value.toString()
                        })
                      }
                    }
                  }
                }
              }
            });
        }
      }
      let filterApplyObj =
      {
        "corporateID": adObj.corporateID,
        "industryID": adObj.industryID,
        "productID": localStorage.getItem('productId'),
        "campaignID": adObj.campaignID,
        "waveID": adObj.waveID,
        "adID": localStorage.getItem('adId'),
        "filters": filterFormList,
        "categories": ["net3Age", "dmGen"],
        "normRequired": "true",
        "statRequired": "true"
      }

      this.apiService.getFilterApplyDetails(filterApplyObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (this.filterApplyList) {
            this.dialogRef.close(this.filterApplyList);
          }
        });
    } else if (this.screenName === "DB2") {
      let filteradObj = JSON.parse(localStorage.getItem('adDetails'));
      for (let p = 0; p < this.filterSampleType.value.length; p++) {
        this.sampleTypefilterValue = this.filterSampleType.value[p] + ',';
      }

      let dashboard2FilterObj = {
        "corporateID": filteradObj.corporateID,
        "industryID": filteradObj.industryID,
        "productID": localStorage.getItem('productId'),
        "campaignID": filteradObj.campaignID,
        "waveID": filteradObj.waveID,
        "adID": localStorage.getItem('adId'),
        "categories": ["net3Age", "dmGen"],
        "normRequired": "true",
        "filters": [
          {
            filterName: "Q1",
            filterType: "MultiSelect",
            filterVal: this.sampleTypefilterValue.slice(0, -1)
          }
        ]
      };
      this.apiService.getDashboardDemoApplyFilter(dashboard2FilterObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (filterApplyRes) {
            this.dialogRef.close(this.filterApplyList);
          }
        });
    } else if (this.screenName === "DB3") {
      this.apiService.onFilterDetails(this.labelValue?.slice(0, -2));
      let adObj = JSON.parse(localStorage.getItem('listadObjInternal'));
      let formValue = Object.entries(this.filterForm.value);
      let filterFormList = [];

      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          this.filterList.filter(
            (x) => {
              if (x.attributeID.toLowerCase().replace(/ +/g, "") === formValue[i][0].toLowerCase()) {
                filterFormList.push({
                  "filterName": x.attributeID,
                  "filterType": x.filterType,
                  "filterVal": formValue[i][1].toString()
                })
              }
            });
        }
      }
      let adIdList = localStorage.getItem('adId').split(',');
      for (let p = 0; p < adIdList.length; p++) {
        for (let m = 0; m < adObj.length; m++) {
          if (adIdList[p]['adId'] === adObj[m].adId) {
            let filterApplyObj = {
              "corporateID": adObj[m].corporateID,
              "industryID": adObj[m].industryID,
              "productID": localStorage.getItem('productId'),
              "campaignID": adObj[m].campaignID,
              "waveID": adObj[m].waveID,
              "adID": adIdList[p],
              "filters": filterFormList,
              "categories": ["net3Age", "dmGen"],
              "normRequired": "true",
              "statRequired": "true"
            }
            this.apiService.getFilterApplyDetails(filterApplyObj).subscribe(
              (filterApplyRes) => {
                if (filterApplyRes != null) {
                  this.internalfilterApplyList.push(filterApplyRes);
                  if (this.internalfilterApplyList.length === adIdList.length && this.internalfilterApplyList) {
                    this.dialogRef.close(this.internalfilterApplyList);
                  }
                }
              });
          }
        }
      }
    } else if (this.screenName === "DB5") {

      if (this.modalName === "NormFilter") {
        let obj = [];

        for (let i = 0; i < this.normFilterValue.length; i++) {
          this.NormList.filter(
            (x) => {
              if (x.attributeID.toLowerCase().replace(/ +/g, "") === this.normFilterValue[i].data.attributeID.toLowerCase()) {
                obj.push({
                  "filterName": x.attributeID,
                  "filterType": x.filterType,
                  "filterVal": this.normFilterValue[i].currentValue
                })
              }
            });
        }

        let normFilterObj = {
          "filters": obj,
          "behaviour": this.behaviour,
          "categories": []
        }
        this.apiService.getInternalNormFilterApply(normFilterObj).subscribe(
          (normFilterRes) => {
            this.normfilterApplyList = normFilterRes;
            if (this.normfilterApplyList) {
              this.dialogRef.close(this.normfilterApplyList);
            }
          });
      } else if (this.modalName === "AdFilter") {
        this.apiService.onFilterDetails(this.labelValue?.slice(0, -2));
        let normadObj = JSON.parse(localStorage.getItem('internalNormAdObj'));
        let formValue = Object.entries(this.filterForm.value);
        let filterFormList = [];

        for (let i = 0; i < formValue.length; i++) {
          if (formValue[i][1] != "") {
            this.filterList.filter(
              (x) => {
                if (x.attributeID.toLowerCase().replace(/ +/g, "") === formValue[i][0].toLowerCase()) {
                  filterFormList.push({
                    "filterName": x.attributeID,
                    "filterType": x.filterType,
                    "filterVal": formValue[i][1].toString()
                  })
                }
              });
          }
        }
        let filterApplyObj =
        {
          "corporateID": normadObj[0].corporateID,
          "industryID": normadObj[0].industryID,
          "productID": localStorage.getItem('internalNormProductId'),
          "campaignID": normadObj[0].campaignID,
          "waveID": normadObj[0].waveID,
          "adID": localStorage.getItem('internalNormAdId'),
          "filters": filterFormList,
          "categories": ["net3Age", "dmGen"],
          "normRequired": "true",
          "statRequired": "true"
        }

        this.apiService.getFilterApplyDetails(filterApplyObj).subscribe(
          (filterApplyRes) => {
            this.filterApplyList = filterApplyRes;
            if (this.filterApplyList) {
              this.dialogRef.close(this.filterApplyList);
            }
          });
      }
    }
  }

  onNormConfirmClick() { }

  onCancelclick() {
    this.dialogRef.close();
  }
}
