import { splitAtColon } from "@angular/compiler/src/util";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from "src/app/shared/api-service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})

export class HeaderComponent implements OnInit {

  public firstName: string;
  public screenName: string;
  public downloadProgress = false;
  public companyLogo;
  public role;
  panelOpenState = false;
  userProfileIcon;


  constructor(
    public router: Router,
    private sanitizer: DomSanitizer,
    private apiService: ApiService
  ) {
    let screenName = 'Dashboard';
    const url = this.router.url.toString();
    switch (url) {
      case '/dashboard': screenName = 'Dashboard'; break;
      case '/dashboard/filterDashboard': screenName = 'Dashboard Demographic'; break;
      case '/dashboard/internalCompare': screenName = 'Ad Comparison'; break;
      case '/dashboard/internalCompareNorm': screenName = 'Norm vs. Ad'; break;
      case '/dashboard/internalNormDemo': screenName = 'Norm Demographic'; break;
      case '/dashboard/internalDemoAds': screenName = 'Ad Comparison Demographic'; break;
      case '/dashboard/marketplace': screenName = 'Marketplace Comparison'; break;
      case '/dashboard/marketplacegenderdemo': screenName = 'Marketplace Comparison By Gender'; break;
      case '/dashboard/marketplaceagedemo': screenName = 'Marketplace Comparison By Age'; break;
      case '/dashboard/videolibrary': screenName = 'Video Library'; break;
      case '/dashboard/Verbatim' : screenName = 'Open-end Data';break;
    }
    localStorage.setItem('screenName', screenName);
  }

  ngOnInit() {
    this.screenName = localStorage.getItem('screenName');
    this.firstName = localStorage.getItem('loginUserName').split('@')[0];
    this.userProfileIcon = this.firstName?.charAt(0).toUpperCase();
    let str = localStorage.getItem('companyLogo');
    this.role = localStorage.getItem('role');

    this.companyLogo = this.sanitizer.bypassSecurityTrustUrl('data:image/svg+xml;base64,' + str);
  }
 
  logOut() {
    this.router.navigateByUrl('/login');
    localStorage.clear();
  }

  onDashboard() {
    localStorage.setItem('screenName', 'Dashboard');
    this.router.navigateByUrl('/dashboard');
  }

  onDashboardDemo() {
    localStorage.setItem('screenName', 'Dashboard Demographic');
    this.router.navigateByUrl('dashboard/filterDashboard');
  }

  onInternalComparisonAds() {
    localStorage.setItem('screenName', 'Ad Comparison');
    this.router.navigateByUrl('dashboard/internalCompare');
  }

  onInternalComparisonNorm() {
    localStorage.setItem('screenName', 'Norm vs. Ad');
    this.router.navigateByUrl('dashboard/internalCompareNorm');
  }
  onInternalNormDemo() {
    localStorage.setItem('screenName', 'Norm Demographic');
    this.router.navigateByUrl('dashboard/internalNormDemo');
  }
  onInternalComparisonDemoAds() {
    localStorage.setItem('screenName', 'Ad Comparison Demographic');
    this.router.navigateByUrl('dashboard/internalDemoAds');

  }

  onInternalComparisonDemoNorm() {

  }
  onMarketPlaceComparison() {
    localStorage.setItem('screenName', 'Marketplace Comparison');
    this.router.navigateByUrl('dashboard/marketplace');
  }
  onMarketPlaceComparisonGenderDemo() {
    localStorage.setItem('screenName', 'Marketplace Comparison By Gender');
    this.router.navigateByUrl('dashboard/marketplacegenderdemo');
  }
  onMarketPlaceComparisonAgeDemo() {
    localStorage.setItem('screenName', 'Marketplace Comparison By Age');
    this.router.navigateByUrl('dashboard/marketplaceagedemo');
  }
  onVideoLibrary() {
    localStorage.setItem('screenName', 'Video Library');
    this.router.navigateByUrl('dashboard/videolibrary');
  }

  setDownloadProgress(progress) {
    this.downloadProgress = progress;
  }

  onLogo() {
    localStorage.setItem('screenName', 'Welcome');
    this.router.navigateByUrl('/welcome');
  }

  onUserManagement() {
    localStorage.setItem('screenName', 'Usermanagement');
    this.router.navigateByUrl('/list');
  }
  onOpenEndData(){
    localStorage.setItem('screenName', 'OpenEndData');
    this.router.navigateByUrl('dashboard/Verbatim');
  }
}
