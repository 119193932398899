import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(public router: Router, private snackBar: MatSnackBar) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.search('/authenticate') > -1) {
      return next.handle(req);
    }
    // All HTTP requests are going to go through this method
    return next.handle(this.getHeaders(req, next)).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 204) {
          this.snackBar.open('No Records Found', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
        }
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.snackBar.open('Session Expired! Please log in again', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
          });
          this.router.navigate(['login']);
        }
      }));
  }

  getHeaders(req: HttpRequest<any>, next: HttpHandler): any {
    let authType = localStorage.getItem('oAuthType');
    let optionsObj;
    let token;
    if (authType === 'local') {
      optionsObj = new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Oauth-type': 'jwt'
      });
    } else {
      if (localStorage.getItem('token') === "undefined" || localStorage.getItem('token') === null) {
        optionsObj = new HttpHeaders({
          'Oauth-type': 'cognito'
        });
      } else {
        optionsObj = new HttpHeaders({
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Oauth-type': 'cognito'
        });
      }
    }
    const authReq = req.clone({
      headers: optionsObj
    });
    return authReq;
  }
}
