<div class="card-body" *ngIf="doughnutChartData1">
    <div class="card-heading">{{doughnutChartMainLabel}}</div>
    <div class="row justify-content-center">

        <div class="col-md-6 mt-4">
            <div class="label-position-dnut-text">
                <div class="num-cap">{{doughnutChartData1[0]}}</div>
                <div class="sub-title-label">{{doughnutChartLabels1[0]}} </div>
                <div *ngIf='doughnutScoreHigh !="noScore"'>
                    <div *ngIf="doughnutScoreHigh">
                        <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                    </div>
                    <div *ngIf="!doughnutScoreHigh">
                        <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                    </div>
                </div>
            </div>

            <canvas baseChart id="doughnut-chart-overall-score" width="195" height="207" [data]="doughnutChartData1"
                [labels]="doughnutChartLabels1" [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                [colors]="[{backgroundColor: ['#fca82e', '#cfd0d6']}]">
            </canvas>
        </div>
        <span class="divider"></span>
        <div class="col-md-6  mt-4">
            <div class="label-position-dnut-text">
                <div class="num-cap">{{doughnutChartData2[0]}}</div>
                <div class="sub-title-label"> {{doughnutChartLabels2[0]}}</div>
            </div>
            <canvas baseChart id="doughnut-chart-normal-avg" width="195" height="207" [data]="doughnutChartData2"
                [labels]="doughnutChartLabels2" [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                [colors]="[{backgroundColor: ['#a9a9a9', '#cfd0d6']}]">
            </canvas>
        </div>
    </div>
</div>