import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from "rxjs";
import { ApiService } from '../../../shared/api-service';

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"]
})

export class FilterComponent implements OnInit {
  panelOpenState = false;

  // common
  @Input() dashboardName;
  @Input() behavior;
  dashboardfilterForm: FormGroup;
  @ViewChild('filterform', { static: true }) filterForm;
  formValue: any;
  fieldList = [];
  differedFields = {};

  // dashboard1
  filterList;
  sampleTypeDisable: boolean;
  fieldDisable: boolean;
  formSelectedItems: any = [];
  labelValue;
  filterApplyList;
  customTitle = new FormControl('', [Validators.required]);

  // dashboard 2
  filterSampleType = new FormControl('');
  filterSampleTypeList = [{
    label: 'Gen Pop',
    value: 1
  },
  {
    label: 'Overall Sample',
    value: 2
  }];
  sampleTypefilterValue;
  demofilterApplyList;
  demoLabelValue;

  // internal 
  internalfilterApplyList = [];

  //internal demo 
  internaldemofilterApplyList = [];

  // internal norm 
  normfilterApplyList;
  normbehaviour;
  normBehaviourList;

  //internal norm demo
  normdemofilterApplyList;
  normdemoadfilterApplyList;

  // market place 
  marketplaceApplyList;
  marketplaceLabelValue = [];
  marketplaceStartLabelValue = [];
  marketAdList = [];
  adMarketLabelList = [];
  matcheckboxList = [];
  marketplaceStartIndicator = [];
  marketplaceLabelIndicator = [];

  // internal ad screen
  internalProductList = [];
  internalAdsList = [];
  listOfAds = [];
  adsDisable;
  internalAdObj;
  compareFlag: boolean = false;

  //
  titleExists: boolean;

  // unscribe

  marketplaceStartIndicatorSubscrption$: Subscription;
  marketplaceGenderSubscription$: Subscription;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.apiService.internalAdsListObserver.subscribe(
      (value) => {
        if (value) {
          let adsValue = [];
          this.listOfAds = JSON.parse(value);
          for (let t = 0; t < this.listOfAds.length; t++) {
            adsValue.push(this.listOfAds[t].adID);
          }

          this.dashboardfilterForm.get('ads').setValue(adsValue);
          if (this.listOfAds.length >= 3) {
            this.adsDisable = true;
          } else {
            this.adsDisable = false;
          }
        }
      });

    if (this.dashboardName === "DB1" || this.dashboardName === "DB3") {
      this.formValue = this.apiService.lastFilterFormValues;
      this.dashboardfilterForm = this.formBuilder.group({});
      this.apiService.getFilterDetails("DB1").subscribe(
        (filterRes) => {
          this.filterList = filterRes;
          for (let k = 0; k < this.filterList.length; k++) {
            this.dashboardfilterForm.addControl(this.filterList[k].attributeID,
              new FormControl(this.formValue[this.filterList[k].attributeID] ? this.formValue[this.filterList[k].attributeID] : []));
          }

          this.apiService.overallSampleTypeObserver.subscribe(value => {
            if (value) {
              if (value === "disable") {
                this.sampleTypeDisable = true;
                this.dashboardfilterForm.get('Q1')?.setValue([]);
              } else {
                this.sampleTypeDisable = false;
                this.dashboardfilterForm.get('Q1')?.setValue([260]);
              }
            }
          });
        });
    } else if (this.dashboardName === "DB2") {
      this.apiService.overallSampleTypeObserver.subscribe(value => {
        if (value) {
          if (value === "disable") {
            this.sampleTypeDisable = true;
            this.filterSampleType.setValue([]);
          } else {
            this.sampleTypeDisable = false;
            this.filterSampleType.setValue([1]);
          }
        }
      });
    } else if (this.dashboardName === "Ad Filter" || this.dashboardName === "MarketPlace Ad Filter" || this.dashboardName === "Compare Ad Filter" || this.dashboardName === "Norm Demo Ad Filter") {
      this.formValue = this.apiService.lastFilterFormValues;
      this.dashboardfilterForm = this.formBuilder.group({});
      this.apiService.getFilterDetails("DB5").subscribe(
        (filterRes) => {
          this.filterList = filterRes;
          for (let k = 0; k < this.filterList.length; k++) {
            this.dashboardfilterForm.addControl(this.filterList[k].attributeID,
              new FormControl(this.formValue[this.filterList[k].attributeID] ? this.formValue[this.filterList[k].attributeID] : []));
          }
          this.apiService.overallSampleTypeObserver.subscribe(value => {
            if (value) {
              if (value === "disable") {
                this.sampleTypeDisable = true;
                this.dashboardfilterForm.get('Q1')?.setValue([]);
              } else {
                this.sampleTypeDisable = false;
                this.dashboardfilterForm.get('Q1')?.setValue(['1']);
              }
            }
          });
        });
    } else if (this.dashboardName === "Norm Filter" || this.dashboardName === "Norm Demo Filter") {
      this.formValue = this.apiService.lastFormValues;
      this.dashboardfilterForm = this.formBuilder.group({});
      this.dashboardfilterForm.addControl('normbehaviour', new FormControl(this.formValue['normbehaviour'] ? this.formValue['normbehaviour'] : []));
      this.apiService.getInternalNormDetails("DB5").subscribe(
        (internalRes) => {
          if (internalRes) {
            this.filterList = internalRes.NormFilter;
            this.normBehaviourList = internalRes.BehaviorFilter[0].filterValues;
            this.dashboardfilterForm.patchValue({
              normbehaviour: ""
            });
            for (let k = 0; k < this.filterList.length; k++) {
              this.dashboardfilterForm.addControl(this.filterList[k].attributeID,
                new FormControl(this.formValue[this.filterList[k].attributeID] ? this.formValue[this.filterList[k].attributeID] : ''));
            }
          }
        });
    } else if (this.dashboardName === "MarketPlace Filter" || this.dashboardName === "MarketPlace Gender Filter" || this.dashboardName === "MarketPlace Age Filter") {
      this.matcheckboxList = [];
      this.dashboardfilterForm = this.formBuilder.group({});
      this.dashboardfilterForm.addControl('customTitle', this.customTitle);
      this.apiService.getInternalNormDetails("DB5").subscribe(
        (normMarketRes) => {
          this.filterList = normMarketRes.NormFilter;
          this.filterList.filter(
            (item) => {
              for (let p = 0; p < item.filterValues.length; p++) {
                this.dashboardfilterForm.addControl(item.filterValues[p].filterId,
                  new FormControl(''));
              }
            });
        });
    } else if (this.dashboardName === "Internal Ad Filter" || this.dashboardName === "Compare Demo Filter") {
      this.formValue = this.apiService.lastFormValues;
      this.dashboardfilterForm = this.formBuilder.group({});
      // this.dashboardfilterForm.addControl('product', new FormControl(this.formValue['product'] ? this.formValue['product'] : []));
      // this.dashboardfilterForm.addControl('ads', new FormControl(this.formValue['ads'] ? this.formValue['ads'] : []));
      const corporateId = localStorage.getItem('corporateId');
      const roleId = localStorage.getItem('role');
      let productDetails = localStorage.getItem('productDetails');
      this.apiService.getProductDetails(productDetails, roleId).subscribe(
        (productRes) => {
          for (let m = 0; m < productRes.products.length; m++) {
            this.internalProductList.push({
              data: productRes.products[m],
              checked: false
            });
          }
          // this.internalProductList = productRes.products;
          // this.internalProductList.filter(
          //   (item) => {
          //       this.dashboardfilterForm.addControl(item.productID,
          //         new FormControl(''));
          //     });
          // if (Object.keys(this.formValue).length > 0) {
          //   this.dashboardfilterForm.patchValue(this.formValue);
          //   this.onInternalProduct({ value: this.formValue.product });
          //   this.onInternalAdName({ value: this.formValue.ads });
          // }
        });
      this.apiService.internalcompareadfilterCloseIndexObserver.subscribe(
        (item: any) => {
          if (item) {
            const data = item;
            this.internalAdsList.length > 0 ? this.internalAdsList.find(x => x.data.adID == data.adID).checked = false : '';
            const ind = this.listOfAds.findIndex(x => x.adID == data.adID);
            this.listOfAds.splice(ind, 1);
            this.labelCheck();


            //this.dashboardfilterForm.get(data.adID).patchValue(false);
            // this.onInternalProduct({ checked: false },item);
            //   this.onInternalAdName({checked:false}, item);
            // const productValue = this.dashboardfilterForm.value.product;
            // const adsValue = this.dashboardfilterForm.value.ads;
            // if (productValue.indexOf(JSON.parse(item.productID)) !== -1) {
            //   productValue.splice(productValue.indexOf(JSON.parse(item.productID)), 1);
            //   adsValue.splice(adsValue.indexOf(JSON.parse(item.adID)), 1);
            //   this.dashboardfilterForm.patchValue({
            //     product: productValue,
            //     ads: adsValue
            //   });

            //}
          }
        });
    }
  }

  onFormValueChange(event, data) {
    let formValue = Object.values(this.dashboardfilterForm.value);
    let formKeyValue = Object.keys(this.dashboardfilterForm.value);
    let filterLabelValue = '';
    this.formSelectedItems = [];
    this.fieldList.push(data)

    for (let i = 0; i < formValue.length; i++) {
      for (let j = 0; j < formKeyValue.length; j++) {
        if (i === j) {
          this.filterList.filter(
            (x) => {
              if (x.attributeID.toLowerCase().replace(/ +/g, "") === formKeyValue[j].toLowerCase()) {
                for (let r = 0; r < x.filterValues.length; r++) {
                  if (formValue[i]['length'] === 0 && event.value.length === undefined) {
                    if (x.filterValues[r].filterId === event.value) {
                      filterLabelValue += x.filterValues[r].attribute_Description + "~ ";
                      if (this.formSelectedItems.indexOf(x.attributeID) === -1) {
                        this.formSelectedItems.push(x.attributeID);
                      }
                    }
                  } else if (formValue[i]['length'] === undefined) {
                    if (x.filterValues[r].attributeID === data.attributeID) {
                      if (x.filterValues[r].filterId === event.value) {
                        filterLabelValue += x.filterValues[r].attribute_Description + "~ ";
                        if (this.formSelectedItems.indexOf(x.attributeID) === -1) {
                          this.formSelectedItems.push(x.attributeID);
                        }
                      }
                    } else {
                      if (x.filterValues[r].filterId === formValue[i]) {
                        filterLabelValue += x.filterValues[r].attribute_Description + "~ ";
                        if (this.formSelectedItems.indexOf(x.attributeID) === -1) {
                          this.formSelectedItems.push(x.attributeID);
                        }
                      }
                    }
                  } else {
                    for (let t = 0; t < formValue[i]['length']; t++) {
                      if (x.filterValues[r].filterId === formValue[i][t]) {
                        filterLabelValue += x.filterValues[r].attribute_Description + "~ ";
                        if (this.formSelectedItems.indexOf(x.attributeID) === -1) {
                          this.formSelectedItems.push(x.attributeID);
                        }
                      }
                    }
                  }
                }
              }
            });
        }
      }
    }
    this.labelValue = filterLabelValue;
    this.fieldDisable = this.formSelectedItems.length >= 3;
  }

  ondashboardConfirmClick() {
    const tempList = {};
    this.fieldList = [...new Set(this.fieldList)];
    let tempFieldList = JSON.parse(JSON.stringify(this.fieldList));
    this.differedFields = {};

    this.fieldList.forEach((field, fieldIndex) => {
      field.filterValues.forEach((value, valueIndex) => {
        if (field.attributeID !== value.attributeID) {
          if (!tempList[value.attributeID]) {
            this.differedFields[field.attributeID] = value.attributeID;
            tempList[value.attributeID] = Object.assign({}, field);
            tempList[value.attributeID].attributeID = value.attributeID;
            tempList[value.attributeID].filterValues = [];
          }
          tempList[value.attributeID].filterValues.push(value);
          delete tempFieldList[fieldIndex].filterValues[valueIndex];
        }
        if (valueIndex === field.filterValues.length - 1) {
          tempFieldList[fieldIndex].filterValues = tempFieldList[fieldIndex].filterValues.filter((val) => val);
        }
      });
    });

    tempFieldList = [...tempFieldList, ...Object.values(tempList)];

    if (this.dashboardName === "Compare Ad Filter") {
      this.apiService.onInternalDemoCompareFilterDetails(this.labelValue?.slice(0, -2));
      let demoadObj = JSON.parse(localStorage.getItem('listdemoadObjInternal'));
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      this.internalfilterApplyList = [];
      this.internaldemofilterApplyList = [];
      this.apiService.internalDemoFilterObj = [];

      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }

      this.apiService.onCompareAdFilterList(JSON.stringify(filterFormList));
      for (let m = 0; m < demoadObj.length; m++) {

        let demoInternalFilterObj = {
          "corporateID": demoadObj[m].corporateID,
          "industryID": demoadObj[m].industryID,
          "productID": demoadObj[m].productID,
          "campaignID": demoadObj[m].campaignID,
          "waveID": demoadObj[m].waveID,
          "adID": demoadObj[m].adID,
          "categories": ["net2Age"],
          "normRequired": "false",
          "filters": filterFormList
        };

        this.apiService.internalDemoFilterObj.push({
          productID: parseInt(demoadObj[m].productID),
          productName: demoadObj[m].productName,
          adName: demoadObj[m].adName,
          adID: demoadObj[m].adID,
          filterFormList: filterFormList
        });
        this.apiService.getDashboardDemoApplyFilter(demoInternalFilterObj).subscribe(
          (filterApplyRes) => {
            if (filterApplyRes != null) {
              this.internaldemofilterApplyList.push(filterApplyRes);
              this.apiService.onInternalDemoCompareDetails(JSON.stringify(this.internaldemofilterApplyList));
            }
          });
      }
    }
    else if (this.dashboardName === "Ad Filter") {
      this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
      this.apiService.onNormAdFilterDetails(this.labelValue?.slice(0, -2));
      let normadObj = JSON.parse(localStorage.getItem('internalNormAdObj'));
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      let normFilterFormList = [];

      this.apiService.nominativefilterObserver.subscribe(
        (value) => {
          if (value) {
            normFilterFormList = JSON.parse(value);
          }
        });


      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }

      let filterApplyObj =
      {
        "corporateID": normadObj.corporateID,
        "industryID": normadObj.industryID,
        "productID": normadObj.productID,
        "campaignID": normadObj.campaignID,
        "waveID": normadObj.waveID,
        "adID": normadObj.adID,
        "behaviour": this.behavior,
        "filters": normFilterFormList,
        "adFilters": filterFormList,
        "categories": [],
        "statRequired": "true"
      }

      this.apiService.getAdNormFilterApplyDetails(filterApplyObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (this.filterApplyList) {
            this.apiService.onAdNormFilter(this.filterApplyList);
          }
        });
    } else if (this.dashboardName === "Norm Demo Ad Filter") {
      this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
      this.apiService.onNormDemoAdFilterDetails(this.labelValue?.slice(0, -2));
      let normadObj = JSON.parse(localStorage.getItem('internalNormDemoAdObj'));
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      let normdemoFilterFormList = [];

      this.apiService.nominativeDemofilterObserver.subscribe(
        (value) => {
          if (value) {
            normdemoFilterFormList = JSON.parse(value);
          }
        });
      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }
      this.apiService.normdemoadFormList = filterFormList;
      let demofilterApplyObj =
      {
        "corporateID": normadObj.corporateID,
        "industryID": normadObj.industryID,
        "productID": normadObj.productID,
        "campaignID": normadObj.campaignID,
        "waveID": normadObj.waveID,
        "adID": normadObj.adID,
        "behaviour": this.behavior,
        "normFilters": normdemoFilterFormList,
        "filters": filterFormList,
        "categories": ["net2Age"],
        "normRequired": "true",
        "screenType": "internal",
      }

      this.apiService.getNormDemoFilterApplyDetails(demofilterApplyObj).subscribe(
        (filterApplyRes) => {
          this.normdemoadfilterApplyList = filterApplyRes;
          if (this.normdemoadfilterApplyList) {
            this.apiService.onAdNormDemoFilter(this.normdemoadfilterApplyList);
          }
        });
    } else if (this.dashboardName === "Norm Filter") {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      let formValue = Object.entries(this.dashboardfilterForm.value);
      this.apiService.onNormFilterDetails(this.labelValue?.slice(0, -2));
      let filterFormList = [];

      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }
      this.apiService.onNominativeFilter(JSON.stringify(filterFormList));
      let normadObj = JSON.parse(localStorage.getItem('internalNormAdObj'));
      let normFilterObj = {
        "corporateID": normadObj.corporateID,
        "industryID": normadObj.industryID,
        "productID": normadObj.productID,
        "campaignID": normadObj.campaignID,
        "waveID": normadObj.waveID,
        "adID": normadObj.adID,
        "filters": filterFormList,
        "behaviour": formValue[0][1],
        "categories": []
      }
      this.apiService.getInternalNormFilterApply(normFilterObj).subscribe(
        (normFilterRes) => {
          this.normfilterApplyList = normFilterRes;
          if (this.normfilterApplyList) {
            this.apiService.onInternalNormFilterDetails(this.normfilterApplyList);
          }
        });
    } else if (this.dashboardName === "Norm Demo Filter") {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      this.apiService.onNormDemoFilterDetails(this.labelValue?.slice(0, -2));
      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }
      this.apiService.normdemoFormList = filterFormList;
      this.apiService.onNominativeDemoFilter(JSON.stringify(filterFormList));
      let normFilterObj = {
        "filters": filterFormList,
        "behaviour": formValue[0][1],
        "categories": ["net2Age"]
      }
      this.apiService.getInternalNormFilterApply(normFilterObj).subscribe(
        (normFilterRes) => {
          this.normdemofilterApplyList = normFilterRes;
          if (this.normdemofilterApplyList) {
            this.apiService.onInternalNormDemoFilterDetails(this.normdemofilterApplyList);
          }
        });






























    } else if (this.dashboardName === "MarketPlace Filter") {
      let filterFormList = [];
      this.marketplaceStartLabelValue = [];
      this.marketplaceStartIndicator = [];
      this.marketplaceLabelIndicator = [];
      for (let t = 0; t < this.filterList.length; t++) {
        let marketplaceValueList;
        for (let r = 0; r < this.matcheckboxList.length; r++) {
          if (this.matcheckboxList[r].attributeID === this.filterList[t].attributeID) {
            marketplaceValueList += this.matcheckboxList[r].attribute_Value.toString() + ",";
            this.marketplaceStartLabelValue.push(this.matcheckboxList[r].attribute_Description);
          }
        }
        if (marketplaceValueList) {
          filterFormList.push({
            "filterName": this.filterList[t].attributeID,
            "filterType": "Multi Select",
            "filterVal": marketplaceValueList.slice(9).slice(0, -1)
          });
        }
      }
      if (this.customTitle.valid) {
        this.marketplaceStartIndicator.push({
          filters: filterFormList,
          behaviour: this.behavior,
          title: this.customTitle.value,
        });
        this.apiService.marketStartIndicator.push(this.marketplaceStartIndicator[0]);
        this.marketplaceLabelIndicator.push({
          labelIndicators: this.marketplaceStartLabelValue,
          title: this.customTitle.value
        });
        this.apiService.startIndicatorLabels.push(this.marketplaceLabelIndicator[0]);

        let marketStartObj = {
          "marketCompareList": this.apiService.marketStartIndicator
        }
        this.marketplaceStartIndicatorSubscrption$ = this.apiService.getMarketplaceStartIndicator(marketStartObj).subscribe(
          (value) => {
            console.log(value)
            if (value) {
              this.apiService.onMarketplaceStartIndicatorDetails(value);
            }
          });

        this.apiService.marketplaceFormList.push({
          filterFormList: filterFormList,
          title: this.customTitle.value
        });
        this.filterForm.resetForm();
      }
    }






    else if (this.dashboardName === "MarketPlace Gender Filter" || this.dashboardName === "MarketPlace Age Filter") {
      let genderfilterFormList = [];
      this.marketplaceLabelValue = [];
      // this.marketplaceStartIndicator = [];

      for (let t = 0; t < this.filterList.length; t++) {
        let marketplaceValueList;
        for (let r = 0; r < this.matcheckboxList.length; r++) {
          if (this.matcheckboxList[r].attributeID === this.filterList[t].attributeID) {
            marketplaceValueList += this.matcheckboxList[r].attribute_Value.toString() + ",";
            this.marketplaceLabelValue.push(this.matcheckboxList[r].attribute_Description);
          }
        }
        if (marketplaceValueList) {
          genderfilterFormList.push({
            "filterName": this.filterList[t].attributeID,
            "filterType": "Multi Select",
            "filterVal": marketplaceValueList.slice(9).slice(0, -1)
          });
        }
      }
      this.adMarketLabelList.push(this.marketplaceLabelValue);
      localStorage.setItem('admarketplaceLabelValue', JSON.stringify(this.adMarketLabelList))
      this.apiService.onMarketplaceLabelDetails(JSON.stringify(this.marketplaceLabelValue));
      const category = this.dashboardName === "MarketPlace Gender Filter" ? { categories: ["dmGen"] }
        : this.dashboardName === "MarketPlace Age Filter" ? { categories: ["net2Age"] } : {};
      let marketFilterObj = {
        "filters": this.apiService.marketpaceadFormList.length > 0 ? genderfilterFormList.concat(this.apiService.marketpaceadFormList) : genderfilterFormList,
        "behaviour": this.behavior,
        "title": this.customTitle.value,
        ...category
      }
      if (this.customTitle.valid) {
        if (genderfilterFormList.length > 0) {
          if (this.dashboardName === "MarketPlace Gender Filter" || this.dashboardName === "MarketPlace Age Filter") {
            this.marketplaceGenderSubscription$ = this.apiService.getMarketPlaceFilterApply(marketFilterObj).subscribe(
              (marketFilterRes) => {
                this.marketplaceApplyList = marketFilterRes;
                let applyList = {
                  marketfilterName: this.marketplaceLabelValue,
                  marketfilterList: this.marketplaceApplyList
                }
                if (this.marketplaceApplyList) {
                  this.apiService.onMarketplaceFilterDetails(JSON.stringify(applyList));
                }
              });
          }
        }
        this.filterForm.resetForm();
      }
      this.marketAdList.push({
        filterFormList: genderfilterFormList,
        title: this.customTitle.value
      });
      this.apiService.onFilterListMarketplace(JSON.stringify(this.marketAdList));

    }


    else if (this.dashboardName === "MarketPlace Ad Filter") {
      this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
      this.apiService.onMarketplaceAdLabelDetails(this.labelValue?.slice(0, -2));
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      this.marketplaceLabelValue = [];
      let marketList;
      this.apiService.filterListObserver.subscribe(
        (value) => {
          if (value) {
            marketList = JSON.parse(value);
          }
        });

      for (let i = 0; i < formValue.length; i++) {
        if (formValue[i][1] != "") {
          for (let m = 0; m < tempFieldList.length; m++) {
            let filterlabVal;
            if (formValue[i][0] === tempFieldList[m].attributeID ||
              (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
                Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
              tempFieldList[m].filterValues.filter(
                (x) => {
                  if (formValue[i][1]['length'] === undefined) {
                    if (x.filterId === formValue[i][1]) {
                      filterlabVal += x.attribute_Value + ",";
                    }
                  } else if (formValue[i][1]['length'] !== undefined) {
                    for (let k = 0; k < formValue[i][1]['length']; k++) {
                      if (x.filterId === formValue[i][1][k]) {
                        filterlabVal += x.attribute_Value + ",";
                      }
                    }
                  }
                });
              if (filterlabVal) {
                filterFormList.push({
                  "filterName": tempFieldList[m].attributeID,
                  "filterType": tempFieldList[m].filterType,
                  "filterVal": filterlabVal.slice(9).slice(0, -1)
                });
              }
            }
          }
        }
      }

      this.apiService.marketpaceadFormList = filterFormList;
      for (let h = 0; h < this.apiService.marketplaceFormList.length; h++) {
        filterFormList = this.apiService.marketplaceFormList[h].filterFormList.concat(this.apiService.marketpaceadFormList);
        let marketFilterObj = {
          "filters": filterFormList,
          "behaviour": "",
          "title": this.apiService.marketplaceFormList[h].title
        }

        this.marketAdList.push({
          filterFormList: filterFormList,
          title: this.apiService.marketplaceFormList[h].title
        });
        if (filterFormList.length > 0) {
          this.apiService.getMarketPlaceFilterApply(marketFilterObj).subscribe(
            (marketFilterRes) => {
              this.marketplaceApplyList = marketFilterRes;
              let applyList = {
                marketfilterName: this.marketplaceLabelValue,
                marketfilterList: this.marketplaceApplyList
              }
              if (this.marketplaceApplyList) {
                this.apiService.onMarketplaceFilterDetails(JSON.stringify(applyList));
              }
            });
        }
      }
      this.apiService.onFilterListMarketplace(JSON.stringify(this.marketAdList));
    } else if (this.dashboardName === "Internal Ad Filter") {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      this.apiService.onInternalCompareAdFilterDetails(this.listOfAds);
    } else if (this.dashboardName === "Compare Demo Filter") {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      this.apiService.onInternalDemoCompareAdFilterDetails(this.listOfAds);
    }
  }

  // onInternalProduct(event) {
  //   let internalProducts = event.value;
  //   this.internalAdsList = [];
  //   this.internalProductList.filter(
  //     (x) => {
  //       for (let p = 0; p < internalProducts.length; p++) {
  //         if (x.productID === internalProducts[p]) {
  //           for (let m = 0; m < x.adDetails.length; m++) {
  //             this.internalAdsList.push(x.adDetails[m]);
  //           }
  //         }
  //       }
  //     });
  //   this.adsDisable = false;
  // }
  onInternalProduct(event, item = null) {
    
    if (event.checked == false) {
      this.internalProductList.find(x => x.data.productID == item.data.productID).checked = false;
      this.internalAdsList = this.internalAdsList.filter(x => x.data.productID != item.data.productID);
      this.listOfAds= this.listOfAds.filter(x => x.productID != item.data.productID);
      this.labelCheck();
         
    }
    else {
      this.internalProductList.find(x => x.data.productID == item.data.productID).checked = true;
      this.internalProductList.forEach(
        (x) => {
          if (x.data.productID === item.data.productID) {
            for (let m = 0; m < x.data.adDetails.length; m++) {
              this.internalAdsList.push({
                data: x.data.adDetails[m],
                checked: false
              });
            }
          }
        });
    }

    this.adsDisable = false;
  }
  onInternalAdName(event, item = null) {
    if (event.checked == false) {
      // const index = this.matadsList.findIndex(x=>x.adID == item.data.adID);
      // this.matadsList.splice(index,1);
      this.internalAdsList.find(x => x.data.adID == item.data.adID).checked = false;

      const ind = this.listOfAds.findIndex(x => x.adID == item.data.adID);
      this.listOfAds.splice(ind, 1);
    }
    else {
     
      localStorage.setItem('adsList', "data");

      let internalAds = item;
           for (let p = 0; p < this.internalAdsList.length; p++) {
        if (this.internalAdsList[p].data.adID === item.data.adID) {
          this.internalAdsList[p].checked = true;
          this.listOfAds.push(this.internalAdsList[p].data);
         
        }
      }
    }
    this.labelCheck();
  }
  // onInternalAdName(event) {
  //   let internalAds = event.value;
  //   this.listOfAds = [];
  //   for (let l = 0; l < event.value.length; l++) {
  //     for (let p = 0; p < this.internalAdsList.length; p++) {
  //       if (this.internalAdsList[p].adID === event.value[l]) {
  //         this.internalAdObj = this.internalAdsList[p];
  //         this.listOfAds.push(this.internalAdsList[p]);
  //       }
  //     }
  //   }
  //   if (internalAds.length >= 3) {
  //     this.adsDisable = true;
  //   } else {
  //     this.adsDisable = false;
  //   }
  // }

  onClear() {
    this.labelValue = "";
    this.fieldDisable = false;
    let clearformValue = Object.keys(this.dashboardfilterForm.controls);
    for (let p = 0; p < clearformValue.length; p++) {
      this.dashboardfilterForm.get(clearformValue[p]).setValue('');
    }
    if (this.dashboardName === "Internal Ad Filter") {
      this.internalAdsList = [];
      this.listOfAds = [];
      this.compareFlag = false;
      this.internalProductList.forEach(
        (x) => x.checked = false);
      this.apiService.onInternalCompareAdFilterDetails([]);
    } else if (this.dashboardName === "Compare Demo Filter") {
      this.internalAdsList = [];
      this.listOfAds = [];
      this.compareFlag = false;
      this.internalProductList.forEach(
        (x) => x.checked = false);
      this.apiService.onInternalDemoCompareAdFilterDetails([]);
    }
  }

  onDemoApply() {
    let filteradObj = JSON.parse(localStorage.getItem('adDetails'));
    for (let p = 0; p < this.filterSampleType.value.length; p++) {
      this.sampleTypefilterValue = this.filterSampleType.value[p] + ',';
      this.filterSampleTypeList.filter(
        x => {
          if (x.value === this.filterSampleType.value[p]) {
            this.demoLabelValue += x.label + ',';
          }
        }
      )
    }
    this.apiService.onShowSpinner("true");
    this.apiService.ondashboardDemoFilterDetails(this.demoLabelValue?.slice(0, -1));

    let dashboard2FilterObj = {
      "corporateID": filteradObj.corporateID,
      "industryID": filteradObj.industryID,
      "productID": filteradObj.productID,
      "campaignID": filteradObj.campaignID,
      "waveID": filteradObj.waveID,
      "adID": filteradObj.adID,
      "categories": ["net3Age", "dmGen"],
      "normRequired": "true",
      "filters": [
        {
          filterName: "Q1",
          filterType: "MultiSelect",
          filterVal: this.sampleTypefilterValue.slice(0, -1)
        }
      ]
    };
    this.apiService.getDashboardDemoApplyFilter(dashboard2FilterObj).subscribe(
      (filterApplyRes) => {
        this.demofilterApplyList = filterApplyRes;
        if (this.demofilterApplyList) {
          this.apiService.onDashboardDemoDetails(this.demofilterApplyList);
          this.apiService.onShowSpinner("false");
          this.filterSampleType.setValue('');
        }
      });
  }

  matcheckboxChange(ev, matItem) {
    if (localStorage.getItem('checkboxList') === "") {
      this.matcheckboxList = [];
    }
    if (ev.checked === true) {
      this.matcheckboxList.push({
        attributeID: matItem.attributeID,
        attribute_Description: matItem.attribute_Description,
        attribute_Value: matItem.attribute_Value,
        filterId: matItem.filterId
      });
      localStorage.setItem('checkboxList', "data");
    } else {
      this.matcheckboxList = this.matcheckboxList.filter((item) => item.filterId !== matItem.filterId);
    }
  }

  onDemoClear() {
    this.filterSampleType.setValue('');
  }

  onTitleChange(event) {
    if (this.apiService.marketTitles.includes(this.customTitle.value)) {
      this.titleExists = true;
    } else {
      this.titleExists = false;
    }
  }
  labelCheck(){
    if (this.listOfAds.length == 0 || this.listOfAds.length >= 3) {
      this.adsDisable = true;
      this.fieldDisable = true
    }
    if (this.listOfAds.length > 0 && this.listOfAds.length < 3) {
      this.adsDisable = false;
      this.fieldDisable = false;
    }
    if (this.listOfAds.length > 0 && this.listOfAds.length <= 3) {
      this.compareFlag = true;
    } else {
      this.compareFlag = false;
    }  
  }

  ngOnDestroy() {

    // this.apiService.marketStartIndicator = [];
    this.marketplaceStartIndicatorSubscrption$ && this.marketplaceStartIndicatorSubscrption$.unsubscribe();
    this.marketplaceGenderSubscription$ && this.marketplaceGenderSubscription$.unsubscribe();
  }
}