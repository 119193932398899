import { Routes } from '@angular/router';
import { CompatibilityComponent } from './common-material-modules/components/compatibility/compatibility.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'compatibility',
        component: CompatibilityComponent
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
    },
    {
        path: 'welcome',
        loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomeModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboards/dashboard.module').then((m) => m.DashboardModule)
    },
    {
        path: 'list',
        loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule)
    }
]