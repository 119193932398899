import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})

export class StackedBarChartComponent implements OnInit {

  constructor(private router: Router) { }
  @Input() stackedbarChartMainLabel: string;
  @Input() stackedbarChartLabel: any;
  @Input() stackedbarChartData: any;
  @Input() stackedbarSetions: any;
  @Input() stackbarIndicators: any;
  @Input() stackbarChartOptions: any;
  @Input() sectionName :any;
  @Input() dataCheck:any;
  stackedbarChartType: ChartType = 'horizontalBar';
  public stackedbarChartLegend = true;

  ngOnInit(): void { }
};
