<div class="container-fluid downloadSpinnerCls">
  <div class="row">
    <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a class="navbar-brand brand-logo" (click)="onLogo()">
          <img class="Sb-logo" src="assets/img/brand_new.png" /><br>
          <img class="harris-logo" src="assets/img/hp_logo.svg" />
        </a>
      </div>

      <div class="navbar-menu-wrapper d-flex align-items-stretch">
        <div class="main-menu d-md-block">
          <ul class="navbar-nav navbar-nav-left">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT' || role === 'BFG_CLIENT'"><span><img
                    src="assets/img/dashboard-icon.svg" width='16'></span><span
                  class="ml-2"></span><span>Dashboard</span>
                 <span class="material-icons">
                  expand_more
                </span></a>
              <div class="dropdown-menu navbar-dropdown">
                <a class="dropdown-item" (click)="onDashboard()"
                  *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Dashboard
                </a>
                <a class="dropdown-item" (click)="onDashboardDemo()"
                  *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Dashboard
                  Demographic</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'"><span><img
                    src="assets/img/internal-icon.svg" width='16'></span><span class="ml-2"></span><span>Ad
                  Comparison</span>
                <span class="material-icons">
                  expand_more
                </span></a>
              <div class="dropdown-menu navbar-dropdown">
                <a class="dropdown-item" (click)="onInternalComparisonAds()"
                  *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Ad
                  Comparison</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="onInternalComparisonDemoAds()"
                  *ngIf="role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Ad
                  Comparison
                  Demographic</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="onInternalComparisonNorm()"
                  *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Norm
                  vs. Ad</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="onInternalNormDemo()"
                  *ngIf=" role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Norm
                  Demo</a>
                <!-- <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="onInternalComparisonDemoNorm()">Norm vs Ad - Standard Demos</a> -->
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'"><span><img
                    src="assets/img/external-icon.svg" width='16'></span><span class="ml-2"></span>
                <span>Marketplace Comparison</span>
                <span class="material-icons">
                  expand_more
                </span></a>
              <div class="dropdown-menu navbar-dropdown">
                <a class="dropdown-item" (click)="onMarketPlaceComparison()"
                  *ngIf="role === 'OPS' || role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'">Marketplace
                  Comparison</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                  *ngIf=" role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'"
                  (click)="onMarketPlaceComparisonGenderDemo()">Marketplace Comparision by
                  Gender</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item"
                  *ngIf=" role === 'ADMIN' || role === 'CUSTOM' || role === 'CLIENT'|| role === 'BFG_CLIENT'"
                  (click)="onMarketPlaceComparisonAgeDemo()">Marketplace Comparision by Age</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" >
                 <span><img
                    src="assets/img/video-icon.svg" width='16'></span><span class="ml-2"></span>
                <span>Library</span>
                <span class="material-icons">
                  expand_more
                </span></a>
                <div class="dropdown-menu navbar-dropdown">
                  <a class="dropdown-item" (click)="onVideoLibrary()">Video
                  Library</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" (click)="onOpenEndData()">Verbatim
                  </a>
                </div>
              
           </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">
                <span>
                  <span *ngIf="!downloadProgress"
                    class="mt-2 material-icons icon-image-preview">arrow_circle_down</span>
                  <mat-spinner *ngIf="downloadProgress" diameter="24"></mat-spinner>
                </span>
                <span class="ml-2"></span>
                <span>Export</span>
                <span class="material-icons">
                  expand_more
                </span></a>
              <div class="dropdown-menu navbar-dropdown">
                <app-download [downloadType]="'PDF'" [screenName]="screenName"
                  (isInProgress)="setDownloadProgress($event)"></app-download>
                <div *ngIf="screenName !== 'Video Library'" class="dropdown-divider"></div>
                <app-download *ngIf="screenName !== 'Video Library'" [downloadType]="'PPT'" [screenName]="screenName"
                  (isInProgress)="setDownloadProgress($event)"></app-download>
                <app-download *ngIf="screenName == 'Open-end Data'" [downloadType]="'Excel'" [screenName]="screenName"
                  (isInProgress)="setDownloadProgress($event)"></app-download>
              </div>
            </li>
          </ul>
        </div>
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle mr-4" id="navbarDropdownMenuLink" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <div class="avatar-sm rounded-circle primary-color text-center">
                {{userProfileIcon}}
              </div>
              <div class="d-flex nav-profile-text">
                <span class="profile-username mt-2 m-1">{{firstName}}</span>
                <span class="mt-1 text-black"> <span class="material-icons">
                    expand_more
                  </span></span>

              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" (click)="onUserManagement()" *ngIf="role === 'ADMIN'"><i
                  class=" fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                User Management</a>
              <a class="dropdown-item" (click)="logOut()"><i
                  class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>