<div class="filter-form">
    <form [formGroup]="dashboardfilterForm" (ngSubmit)="ondashboardConfirmClick()" #filterform="ngForm">
        <div>
            <div class="p-2 pl-3" *ngFor="let item of this.filterList">
                <div *ngIf="item?.filterType === 'SingleSelect'">
                    <label class="custom-label m-0">{{item?.attributeName}}</label>
                    <mat-radio-group aria-labelledby="Select an option" [formControlName]="item?.attributeID">
                        <mat-radio-button *ngFor="let norm of item?.filterValues"
                            (change)="onFormValueChange($event,item)" [value]="norm.filterId"
                            [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)">
                            {{norm.attribute_Description}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="item?.filterType === 'MultiSelect'">
                    <div *ngIf="item.attributeName !== 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="item.attributeName === 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1) || sampleTypeDisable"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between">
            <button mat-raised-button color="primary" id="confirmBtn" class="applyCls" tabindex="1">
                <span class="applyCls">Apply</span></button>
            <button mat-raised-button id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
    </form>
</div>