import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, SingleOrMultiDataSet } from 'ng2-charts';

@Component({
  selector: "multi-doughnut-chart",
  templateUrl: "./multi-doughnut-chart.component.html",
  styleUrls: ["./multi-doughnut-chart.component.scss"]
})

export class MultiDoughnutChartComponent implements OnInit {

  @Input() multiSectionScore;
  @Input() multiDoughnutChartMainLabel;
  @Input() multiDoughnutChartLabels1;
  @Input() multiDoughnutChartLabels2;
  @Input() multiDoughnutChartData1;
  @Input() multiDoughnutChartData2;
  @Input() multiDoughnutScoreHigh;


  doughnutChartType: ChartType = 'pie';
  doughnutChartOptions: ChartOptions = {
    maintainAspectRatio: true,
    cutoutPercentage: 60,
    //circumference: 5,
    responsive: true,
    animation: {
      duration: 2000
    },
    tooltips: {
      enabled: false
    },
    legend: {
      display: false,
      onClick: null,
      labels: {
        boxWidth: 15,
      }
    },
    plugins: {
      datalabels: {
        display: false
      },
      // datasets: [{
      //   backgroundColor: ['#ffffff', '#4caf50', '#0087CE', '#ffffff'],
      //   hoverBackgroundColor: ['#ffffff', '#4caf50', '#0087CE', '#ffffff']
      // }],
    }
  };

  constructor(private router: Router,
    private sanitizer: DomSanitizer) { }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };

  randomScalingFactor() {
    return Math.round(Math.random() * 100);
  };

  ngOnInit() { }
}
