import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExportService } from '../../../shared/export-service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  @Input() screenName;
  @Input() downloadType;
  @Output() isInProgress = new EventEmitter<boolean>();
  constructor(private exportService: ExportService) { }

  ngOnInit(): void { }

  downloadScreen() {
    this.isInProgress.emit(true);
    switch (this.downloadType) {
      case 'PDF':
        this.exportService.generatePDF(this.screenName, DownloadConfiguration[this.screenName], (progress) => this.isInProgress.emit(progress));
        break;
      case 'PPT':
        this.exportService.generatePPT(this.screenName, DownloadConfiguration[this.screenName], (progress) => this.isInProgress.emit(progress));
        break;
      case 'Excel':
        this.exportService.generateXLSX(this.screenName, DownloadConfiguration[this.screenName], (progress) => this.isInProgress.emit(progress));
        break;
    }
  }
}

const DownloadConfiguration = {
  'Dashboard' : ['showProductAdLabels'],
  'Dashboard Demographic' : ['showProductAdLabels'],
  'Ad Comparison' : [],
  'Norm vs. Ad' : ['showProductAdLabels'],
  'Norm Demographic' : ['showProductAdLabels'],
  'Ad Comparison Demographic' : [],
  'Marketplace Comparison' : [],
  'Marketplace Comparison By Gender' : ['noFilter'],
  'Marketplace Comparison By Age' : ['noFilter'],
  'Video Library' : []
};
