<div *ngIf="sectionName === 'Section3'" class="card-body">
    <div class="card-heading">
        <span>This ad helps me make... </span><br />
        <!-- {{stackedbarChartMainLabel}} -->
    </div>
    <div class="panel-body">
        <div class="row">
            <div class="col-md-8" *ngIf="this.stackedbarChartData[0]">
                <div class="stacked-graph">
                    <div class="data data-1" title="{{this.stackedbarChartData[0]['data'][0]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[0]['data'][0]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][0] >= 4">
                            {{this.stackedbarChartData[0]['data'][0]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][0] < 4"></div>
                    </div>
                    <div class="data data-2" title="{{this.stackedbarChartData[1]['data'][0]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[1]['data'][0]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][0] >= 4">
                            {{this.stackedbarChartData[1]['data'][0]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][0] < 4"></div>
                    </div>
                    <div class="data data-3" title="{{this.stackedbarChartData[2]['data'][0]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[2]['data'][0]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][0] >= 4">
                            {{this.stackedbarChartData[2]['data'][0]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][0] < 4"></div>
                    </div>
                    <div class="data data-4" title="{{this.stackedbarChartData[3]['data'][0]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[3]['data'][0]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][0] >= 4">
                            {{this.stackedbarChartData[3]['data'][0]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][0] < 4"></div>
                    </div>
                    <div class="data data-5" title="{{this.stackedbarChartData[4]['data'][0]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[4]['data'][0]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][0] >= 4">
                            {{this.stackedbarChartData[4]['data'][0]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][0] < 4"></div>
                    </div>
                </div>

                <div class="stacked-graph">
                    <div class="data data-1" title="{{this.stackedbarChartData[0]['data'][1]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[0]['data'][1]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][1] >= 4">
                            {{this.stackedbarChartData[0]['data'][1]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][1] < 4"></div>
                    </div>
                    <div class="data data-2" title="{{this.stackedbarChartData[1]['data'][1]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[1]['data'][1]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][1] >= 4">
                            {{this.stackedbarChartData[1]['data'][1]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][1] < 4"></div>
                    </div>
                    <div class="data data-3" title="{{this.stackedbarChartData[2]['data'][1]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[2]['data'][1]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][1] >= 4">
                            {{this.stackedbarChartData[2]['data'][1]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][1] < 4"></div>
                    </div>
                    <div class="data data-4" title="{{this.stackedbarChartData[3]['data'][1]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[3]['data'][1]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][1] >= 4">
                            {{this.stackedbarChartData[3]['data'][1]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][1] < 4"></div>
                    </div>
                    <div class="data data-5" title="{{this.stackedbarChartData[4]['data'][1]}}%"
                        [ngStyle]="{'width': this.stackedbarChartData[4]['data'][1]+'%'}">
                        <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][1] >= 4">
                            {{this.stackedbarChartData[4]['data'][1]}}%</div>
                        <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][1] < 4"></div>
                    </div>
                </div>
                <div class="legends">
                    <span class="legend-color strong-disagree-color"></span>
                    <span class="legend-tick">Strongly disgree</span>
                    <span class="legend-color disagree-color"></span>
                    <span class="legend-tick">Disgree</span>
                    <span class="legend-color nanda-color"></span>
                    <span class="legend-tick">Neither agree nor disagree</span>
                    <span class="legend-color agree-color"></span>
                    <span class="legend-tick">Agree</span>
                    <span class="legend-color strong-agree-color"></span>
                    <span class="legend-tick">Strongly agree</span>
                </div>
            </div>
            <div class="col-md-4 text-center" *ngIf="this.stackedbarSetions">
                <div class="first-agree-stats">
                    <span class="sub-title-label">
                        {{stackedbarChartLabel[0]}}
                    </span>
                    <div class="d-flex justify-content-center">
                        <div class="label-position-dnut-one">
                            <div class="num-cap">{{this.stackedbarSetions["stackedAgreedata1"]}}%</div>
                            <div class="green-underline">Agree Net</div>
                            <div *ngIf='this.stackbarIndicators[0] !="noScore"'>
                                <div *ngIf=this.stackbarIndicators[0]>
                                    <img src="assets/img/icon_positive.svg" alt="" height="25" width="15">
                                </div>
                                <div *ngIf='!this.stackbarIndicators[0]'><img src="assets/img/icon_negative.svg" alt=""
                                        height="25" width="15"></div>
                            </div>
                        </div>
                        <div class="label-position-dnut-two">
                            <div class="num-cap">{{this.stackedbarSetions["stackedNorm1"]}}%</div>
                            <div class="grey-underline">Norm</div>
                        </div>
                    </div>
                </div>
                <div class="second-agree-stats">
                    <span class="sub-title-label">
                        {{stackedbarChartLabel[1]}}
                    </span>
                    <div class="d-flex justify-content-center">
                        <div class="label-position-dnut-one">
                            <div class="num-cap">{{this.stackedbarSetions["stackedAgreedata2"]}}%</div>
                            <div class="green-underline">Agree Net</div>
                            <div *ngIf='this.stackbarIndicators[1] !="noScore"'>
                                <div *ngIf="this.stackbarIndicators[1]">
                                    <img src="assets/img/icon_positive.svg" alt="" height="25" width="15">
                                </div>
                                <div *ngIf='!this.stackbarIndicators[1]'><img src="assets/img/icon_negative.svg" alt=""
                                        height="25" width="15"></div>
                            </div>
                        </div>
                        <div class="label-position-dnut-two">
                            <div class="num-cap">{{this.stackedbarSetions["stackedNorm2"]}}%</div>
                            <div class="grey-underline">Norm</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="sectionName === 'Section6'" class="card-body">
    <div class="card-heading">
        <span>After seeing the ad, how would you rate your opinion of the brand? </span><br />
        <!-- {{stackedbarChartMainLabel}} -->
    </div>
    <div *ngIf='!dataCheck'>
        Data not available - Metric added after this ad was tested
    </div>
    <div *ngIf=dataCheck>
        <div class="panel-body">
            <div class="row">
                <div class="col-md-8" *ngIf="this.stackedbarChartData[0]">
                    <div class="stacked-graph">
                        <div class="data data-1" title="{{this.stackedbarChartData[0]['data'][0]}}%"
                            [ngStyle]="{'width': this.stackedbarChartData[0]['data'][0]+'%'}">
                            <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][0] >= 4">
                                {{this.stackedbarChartData[0]['data'][0]}}%</div>
                            <div class="data-inner" *ngIf="this.stackedbarChartData[0]['data'][0] < 4"></div>
                        </div>
                        <div class="data data-2" title="{{this.stackedbarChartData[1]['data'][0]}}%"
                            [ngStyle]="{'width': this.stackedbarChartData[1]['data'][0]+'%'}">
                            <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][0] >= 4">
                                {{this.stackedbarChartData[1]['data'][0]}}%</div>
                            <div class="data-inner" *ngIf="this.stackedbarChartData[1]['data'][0] < 4"></div>
                        </div>
                        <div class="data data-3" title="{{this.stackedbarChartData[2]['data'][0]}}%"
                            [ngStyle]="{'width': this.stackedbarChartData[2]['data'][0]+'%'}">
                            <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][0] >= 4">
                                {{this.stackedbarChartData[2]['data'][0]}}%</div>
                            <div class="data-inner" *ngIf="this.stackedbarChartData[2]['data'][0] < 4"></div>
                        </div>
                        <div class="data data-4" title="{{this.stackedbarChartData[3]['data'][0]}}%"
                            [ngStyle]="{'width': this.stackedbarChartData[3]['data'][0]+'%'}">
                            <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][0] >= 4">
                                {{this.stackedbarChartData[3]['data'][0]}}%</div>
                            <div class="data-inner" *ngIf="this.stackedbarChartData[3]['data'][0] < 4"></div>
                        </div>
                        <div class="data data-5" title="{{this.stackedbarChartData[4]['data'][0]}}%"
                            [ngStyle]="{'width': this.stackedbarChartData[4]['data'][0]+'%'}">
                            <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][0] >= 4">
                                {{this.stackedbarChartData[4]['data'][0]}}%</div>
                            <div class="data-inner" *ngIf="this.stackedbarChartData[4]['data'][0] < 4"></div>
                        </div>
                    </div>
                    <div class="legends">
                        <div class="row">
                            <div>
                                <span class="legend-color strong-disagree-color"></span>
                                <span class="legend-tick">Much less favorable</span>
                            </div>
                            <div>
                                <span class="legend-color disagree-color"></span>
                                <span class="legend-tick">Somewhat less favorable</span>
                            </div>
                            <div>
                                <span class="legend-color nanda-color"></span>
                                <span class="legend-tick">No change in my opinion of the brand</span>
                            </div>

                        </div>
                        <div class="row">
                            <div>
                                <span class="legend-color agree-color"></span>
                                <span class="legend-tick">Somewhat more favorable</span>
                            </div>
                            <div>
                                <span class="legend-color strong-agree-color"></span>
                                <span class="legend-tick">Much more favorable</span>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 text-center" *ngIf="this.stackedbarSetions">
                    <div class="first-agree-stats">
                        <span class="sub-title-label">
                            {{stackedbarChartLabel[0]}}
                        </span>
                        <div class="d-flex justify-content-center">
                            <div class="label-position-dnut-one">
                                <div class="num-cap">{{this.stackedbarSetions['stackedAgreedataQ6']}}%</div>
                                <div class="green-underline">Favorable Net</div>
                                <div *ngIf='this.stackbarIndicators[0] !="noScore"'>
                                    <div *ngIf=this.stackbarIndicators[0]>
                                        <img src="assets/img/icon_positive.svg" alt="" height="25" width="15">
                                    </div>
                                    <div *ngIf='!this.stackbarIndicators[0]'><img src="assets/img/icon_negative.svg"
                                            alt="" height="25" width="15"></div>
                                </div>
                            </div>
                            <div class="label-position-dnut-two">
                                <div class="num-cap">{{this.stackedbarSetions["stackedNorm1"]}}%</div>
                                <div class="grey-underline">Norm</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row" style="padding-top: 45px;">
                        <img src="assets/img/icon_positive.svg" height="20" width="15" alt="">
                        <img src="assets/img/icon_negative.svg" height="20" width="15" alt="">
                        <span class="indicators-legend">
                            = statistically <span class="green"> higher </span> / <span class="red"> lower </span> against norm
                        </span>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
    <div class="row" style="float: right;padding-bottom: 15px;">
        <img src="assets/img/icon_positive.svg" height="20" width="15" alt="">
        <img src="assets/img/icon_negative.svg" height="20" width="15" alt="">
        <span class="indicators-legend">
            = statistically <span class="green"> higher </span> / <span class="red"> lower </span> against norm
        </span>
    </div>
</div>