import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-compatibility',
  templateUrl: './compatibility.component.html',
  styleUrls: ['./compatibility.component.scss']
})
export class CompatibilityComponent implements OnInit {

  device;
  constructor(private deviceservice: DeviceDetectorService) { }

  ngOnInit(): void {
    this.device = this.deviceservice.deviceType;
  }

}
