import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    // common
    public lastFormValues: any = {};
    public lastFilterFormValues: any = {};
    public loginUserName: any = {};
    public marketpaceadFormList: any = [];
    public marketplaceFormList: any = [];
    public normdemoFormList: any = [];
    public normdemoadFormList: any = [];
    public showloadSpinner: boolean;
    // marketplace start indicator

    public marketStartIndicator: any = [];
    public startIndicatorLabels: any = [];
    public marketTitles: any = [];

    // [x: string]: any;

    public dashboardDetails = new BehaviorSubject<string>('');
    castUser = this.dashboardDetails.asObservable();

    public filterLabelDetails = new BehaviorSubject<string>('');
    filtercastUser = this.filterLabelDetails.asObservable();

    // public variables
    public messageSource = new BehaviorSubject(null);
    public currentMessage = this.messageSource.asObservable();
    public storageData = 'TDHLocal';
    // private base_url: string = "server";
    private base_url: string = environment.URL;
    public loginResponseError;

    // public httpOptions = {
    //     headers: new HttpHeaders({
    //         'Content-Type': 'application/json; charset=utf-8',
    //         'Access-Control-Allow-Origin': '*',
    //         'Oauth-type': 'jwt'
    //     })
    // };
    loginURL: string = this.base_url + "/authenticate";
    cognitologinURL: string = this.base_url + "/authenitcateBFGUser";
    productURL: string = this.base_url + "/dashboard/getProductAdMappings/";
    dashboardURL: string = this.base_url + "/dashboard/getDashBoardDetails/";
    filterURL: string = this.base_url + "/dashboard/filter/getFilters/";
    filterApplyURL: string = this.base_url + "/dashboard/getFilteredDashBoardDetails";
    dashboardDemoURL: string = this.base_url + "/dashboard/getDashBoard2Details/";
    dashboardDemoFilterURL: string = this.base_url + "/dashboard/getFilteredDashBoard2Details/";
    internalNormURL = this.base_url + "/dashboard/filter/getNormFilters/";
    internalNormFilterApplyURL = this.base_url + "/internal/getFilteredNormDetails";
    marketPlaceURL = this.base_url + "/marketplace/getFilteredDashBoardDetails";
    adfilterApplyURL: string = this.base_url + "/internal/getFilteredDashBoardDetails"
    videoLibraryURL = this.base_url + "/dashboard/getVideoLibraryDetails";
    q3DashboardURL = this.base_url + "/dashboard/getQ3DashBoardDetails";

    // internal compare start indicator

    internalCompareStartIndicatiorURL = this.base_url + "/internal/getStatDashBoardDetails";
    internalCompareFilterStartIndicatiorURL = this.base_url + "/internal/getStatFilteredDashBoardDetails";

    // marketplace start indicatior

    marketplaceStartIndicator = this.base_url + "/marketplace/getStatFilteredDashBoardDetails";
    // SSO 
    cognitoProductList = this.base_url + "/getDetailsByUserName";

    // landing page
    landingURL = this.base_url + "/dashboard/getLandingPageDetails";

    // user management

    userListURL = this.base_url + "/userMgmt/userList";
    createUserURL = this.base_url + "/userMgmt/userInfo";
    deleteUserURL = this.base_url + "/userMgmt/user";
    productsUserURL = this.base_url + "/userMgmt/products";
    roleUserURL = this.base_url + "/userMgmt/roles";
    updateUserURL = this.base_url + "/userMgmt/updateUserInfo";
    cognitoUserURL = this.base_url + "/userMgmt/migrateUserPoolData";
    unMapUserURL = this.base_url + "/userMgmt/unmapUserFromProduct";
    allUsersURL = this.base_url + "/userMgmt/allUsers";
    // allUsersURL = this.base_url + "/productMapping";
    productMappingURL = this.base_url + "/userMgmt/updateProductUserInfo";




    // landing page
    getLandingPage(landingObj) {
        return this._http.post(this.landingURL, landingObj);
    }

    // Dashboard Screen 

    // filter
    public filterSource = new BehaviorSubject({});
    filterObserver = this.filterSource.asObservable();

    onFilterDetails(isFilter: any) {
        this.filterSource.next(isFilter);
    }

    // filter cancel
    public filterCancelSource = new BehaviorSubject({});
    filterCancelObserver = this.filterCancelSource.asObservable();

    onFilterCancelDetails(isFilterCancel: any) {
        this.filterCancelSource.next(isFilterCancel);
    }

    // compare filter
    public compareFilterSource = new BehaviorSubject([]);
    compareFilterObserver = this.compareFilterSource.asObservable();

    onCompareFilterDetails(isCompareFilter: any) {
        this.compareFilterSource.next(isCompareFilter);
    }
    // new
    public clearAdFilter = new BehaviorSubject([]);
    clearAdFilterObserver = this.compareFilterSource.asObservable();

    onClearAdFilter(isAdFilter: any) {
        this.clearAdFilter.next(isAdFilter);
    }

    // compare filter cancel
    public compareFilterCancelSource = new BehaviorSubject([]);
    compareFilterCancelObserver = this.compareFilterCancelSource.asObservable();

    onCompareFilterCancelDetails(isCompareFilterCancel: any) {
        this.compareFilterCancelSource.next(isCompareFilterCancel);
    }

    // load charts data in dashboard
    public dashboardSource = new BehaviorSubject('');
    dashboardObserver = this.dashboardSource.asObservable();

    onDashboardDetails(isDashboard: string) {
        this.dashboardSource.next(isDashboard);
    }
    //Verbatim
    public VerbatimSource = new BehaviorSubject('');
    VerbatimObserver = this.VerbatimSource.asObservable();

    onVerbatimDetails(isVerbatim: any) {
        this.VerbatimSource.next(isVerbatim);
    }

    // get the filter form selected items

    // public filterFormSelectedItemSource = new BehaviorSubject('');
    // filterFormSelectedItemObserver = this.filterFormSelectedItemSource.asObservable();

    // onFilterFormSelectedItemDetails(isFilterSelectedList: string) {
    //     this.filterFormSelectedItemSource.next(isFilterSelectedList);
    // }












    // overSample Type disable
    public overSampleTypeSource = new BehaviorSubject('');
    overallSampleTypeObserver = this.overSampleTypeSource.asObservable();


    onOverallSampleDetails(isOverSample: string) {
        this.overSampleTypeSource.next(isOverSample);
    }


    // dashboard demo filter label value
    public dashboardDemofilterSource = new BehaviorSubject('');
    dashboardDemofilterObserver = this.dashboardDemofilterSource.asObservable();

    ondashboardDemoFilterDetails(isdemoFilter: string) {
        this.dashboardDemofilterSource.next(isdemoFilter);
    }

    // internal standard demo
    public internaldemocomparefilterSource = new BehaviorSubject('');
    internaldemocomparefilterObserver = this.internaldemocomparefilterSource.asObservable();

    onInternalDemoCompareFilterDetails(isdemocompareFilter: string) {
        this.internaldemocomparefilterSource.next(isdemocompareFilter);
    }

    // internal standard demo filterlist
    public compareadfilterlistSource = new BehaviorSubject('');
    compareadfilterlistObserver = this.compareadfilterlistSource.asObservable();
    onCompareAdFilterList(isCompareDemo: string) {
        this.compareadfilterlistSource.next(isCompareDemo);
    }


    // internal compare filter
    public internalcomparefilterSource = new BehaviorSubject('');
    internalcomparefilterObserver = this.internalcomparefilterSource.asObservable();

    onInternalCompareFilterDetails(iscompareFilter: string) {
        this.internalcomparefilterSource.next(iscompareFilter);
    }

    public internalcompareadfilterSource = new BehaviorSubject([]);
    internalcompareadfilterObserver = this.internalcompareadfilterSource.asObservable();

    onInternalCompareAdFilterDetails(iscompareadFilter: any[]) {
        this.internalcompareadfilterSource.next(iscompareadFilter);
    }

    // internal demo  compare filter

    public internaldemocompareadfilterSource = new BehaviorSubject([]);
    internaldemocompareadfilterObserver = this.internaldemocompareadfilterSource.asObservable();

    onInternalDemoCompareAdFilterDetails(isdemocompareadFilter: any[]) {
        this.internaldemocompareadfilterSource.next(isdemocompareadFilter);
    }

    public internalcompareadfilterCloseIndex = new BehaviorSubject({});
    internalcompareadfilterCloseIndexObserver = this.internalcompareadfilterCloseIndex.asObservable();

    onInternalCompareAdFilterCloseIndexDetails(iscompareadFilter: any) {
        this.internalcompareadfilterCloseIndex.next(iscompareadFilter);
    }

    // nominative filter list in ad filter

    public nominativefilterSource = new BehaviorSubject('');
    nominativefilterObserver = this.nominativefilterSource.asObservable();

    onNominativeFilter(isnominativeFilter: string) {
        this.nominativefilterSource.next(isnominativeFilter);
    }

    // nominative filter list in norm demo 

    public nominativeDemofilterSource = new BehaviorSubject('');
    nominativeDemofilterObserver = this.nominativefilterSource.asObservable();

    onNominativeDemoFilter(isNominativeFilter: string) {
        this.nominativefilterSource.next(isNominativeFilter);
    }

    // internal norm (ad) filter

    public adnormfilterSource = new BehaviorSubject('');
    adnormfilterObserver = this.adnormfilterSource.asObservable();

    onAdNormFilter(isadNormFilter: string) {
        this.adnormfilterSource.next(isadNormFilter);
    }

    // internal norm demo ad filter 

    public adnormdemofilterSource = new BehaviorSubject('');
    adnormdemofilterObserver = this.adnormdemofilterSource.asObservable();

    onAdNormDemoFilter(isadNormDemoFilter: string) {
        this.adnormdemofilterSource.next(isadNormDemoFilter);
    }



    // inter norm(ad ) based on product

    public internalnormAdfilterSource = new BehaviorSubject('');
    internalnormAdfilterObserver = this.internalnormAdfilterSource.asObservable();

    onInternalNormAdFilterDetails(isnormadFilter: string) {
        this.internalnormAdfilterSource.next(isnormadFilter);
    }

    // norm demo ad filter
    public internalnormdemoAdfilterSource = new BehaviorSubject('');
    internalnormdemoAdfilterObserevr = this.internalnormdemoAdfilterSource.asObservable();


    onInternalNormDemoAdFilterDetails(isnormdemoadFilter: string) {
        this.internalnormdemoAdfilterSource.next(isnormdemoadFilter);
    }
    // internal norm(norm) filter

    public internalnormfilterSource = new BehaviorSubject('');
    internalnormfilterObserver = this.internalnormfilterSource.asObservable();

    onInternalNormFilterDetails(isnormFilter: string) {
        this.internalnormfilterSource.next(isnormFilter);
    }
    // Norm Demo Behaviour change

    public internalNormDemofilterSource = new BehaviorSubject('');
    internalNormdemofilterObserver = this.internalNormDemofilterSource.asObservable();

    onInternalNormDemoFilterDetails(isNormDemoFilter: string) {
        this.internalNormDemofilterSource.next(isNormDemoFilter);
    }


    // internal norm(ad) filter label

    public normadfilterSource = new BehaviorSubject('');
    normAdfilterObserver = this.normadfilterSource.asObservable();
    onNormAdFilterDetails(isAdFilter: string) {
        this.normadfilterSource.next(isAdFilter);
    }

    // internal norm filter label

    public normfilterSource = new BehaviorSubject('');
    normfilterObserver = this.normfilterSource.asObservable();

    onNormFilterDetails(isFilter: string) {
        this.normfilterSource.next(isFilter);
    }

    // internal norm demo filter label 

    public normdemofilterSource = new BehaviorSubject('');
    normDemoFilterObserver = this.normdemofilterSource.asObservable();

    onNormDemoFilterDetails(isDemoFilter: string) {
        this.normdemofilterSource.next(isDemoFilter);
    }

    // internal norm demo ad filter label

    public normdemoadfilterSource = new BehaviorSubject('');
    normdemoAdFilterObserver = this.normdemoadfilterSource.asObservable();

    onNormDemoAdFilterDetails(isAdDemoFilter: string) {
        this.normdemoadfilterSource.next(isAdDemoFilter);
    }

    // marketplace 

    public marketplacefilterSource = new BehaviorSubject('');
    marketplacefilterObserver = this.marketplacefilterSource.asObservable();

    onMarketplaceFilterDetails(isMarketPlaceFilter: string) {
        this.marketplacefilterSource.next(isMarketPlaceFilter);
    }

    // marketplace filter label values

    public marketplacefilterLabelSource = new BehaviorSubject('');
    marketplacefilterLabelObserver = this.marketplacefilterLabelSource.asObservable();

    onMarketplaceLabelDetails(isMarketplaceLabel: string) {
        this.marketplacefilterLabelSource.next(isMarketplaceLabel);
    }

    // market place as filter label

    public marketplaceadfilterLabelSource = new BehaviorSubject('');
    marketplaceadfilterLabelObserver = this.marketplaceadfilterLabelSource.asObservable();

    onMarketplaceAdLabelDetails(isAdFilterLabel: string) {
        this.marketplaceadfilterLabelSource.next(isAdFilterLabel);
    }

    // market place all filter list 

    public filterListLabelSource = new BehaviorSubject('');
    filterListObserver = this.filterListLabelSource.asObservable();

    onFilterListMarketplace(isFilterList: string) {
        this.filterListLabelSource.next(isFilterList);
    }



    // dashboard demo charts  
    private dashboardDemoSource = new BehaviorSubject('');
    dashboardDemoObserver = this.dashboardDemoSource.asObservable();

    onDashboardDemoDetails(isDashboardDemo: string) {
        this.dashboardDemoSource.next(isDashboardDemo);
    }

    // internal compare charts
    private internalCompareSource = new BehaviorSubject('');
    internalCompareObserver = this.internalCompareSource.asObservable();
    internalFilterObj: any = [];
    onInternalCompareDetails(isInternalCompare: string) {
        this.internalCompareSource.next(isInternalCompare);
    }

    // internal demo compare charts

    private internaldemoCompareSource = new BehaviorSubject('');
    internaldemoCompareObserver = this.internaldemoCompareSource.asObservable();
    internalDemoFilterObj: any = [];
    onInternalDemoCompareDetails(isInternaldemoCompare: string) {
        this.internaldemoCompareSource.next(isInternaldemoCompare);
    }

    // selected ad list in internal compare
    private internalAdsListSource = new BehaviorSubject('');
    internalAdsListObserver = this.internalAdsListSource.asObservable();

    onInternalAdListDetails(isInternaladList: string) {
        this.internalAdsListSource.next(isInternaladList);
    }

    public videoLibraryfilterSource = new BehaviorSubject([]);
    videoLibraryfilterObserver = this.videoLibraryfilterSource.asObservable();

    onVideoLibraryFilterDetails(isVideoFilter: any[]) {
        this.videoLibraryfilterSource.next(isVideoFilter);
    }

    constructor(private _http: HttpClient) { }

    getLoginDetails(obj): any {
        return this._http.post(this.loginURL, obj);
    }

    getCognitoLoginDetails(cognitoObj): any {
        return this._http.post(this.cognitologinURL, cognitoObj);
    }

    getProductDetails(productDetails, roleId): any {
        const headerObj = {
            productDetails: JSON.parse(productDetails),
            roleId
        };
        return this._http.post(this.productURL, headerObj);
    }

    getDashboardDetails(obj): any {
        return this._http.post(this.dashboardURL, obj)
    }

    getFilterDetails(dashBoardName): any {
        return this._http.get(this.filterURL + dashBoardName);
    }

    getFilterApplyDetails(filterObj): any {
        return this._http.post(this.filterApplyURL, filterObj);
    }

    getAdNormFilterApplyDetails(adfilterObj): any {
        return this._http.post(this.adfilterApplyURL, adfilterObj);
    }

    getNormDemoFilterApplyDetails(addemofilterObj): any {
        return this._http.post(this.dashboardDemoFilterURL, addemofilterObj)
    }

    getDashboardDemoDetails(dashboardDemoObj): any {
        return this._http.post(this.dashboardDemoURL, dashboardDemoObj);
    }

    getDashboardDemoApplyFilter(dashboardDemoFilterObj): any {
        return this._http.post(this.dashboardDemoFilterURL, dashboardDemoFilterObj);
    }

    getInternalNormDetails(screenName): any {
        return this._http.get(this.internalNormURL + screenName);
    }

    getInternalNormFilterApply(normFilterObj): any {
        return this._http.post(this.internalNormFilterApplyURL, normFilterObj);
    }

    getMarketPlaceFilterApply(marketFilterObj): any {
        return this._http.post(this.marketPlaceURL, marketFilterObj);
    }

    getvideoLibrary(videoLibraryObj): any {
        return this._http.post(this.videoLibraryURL, videoLibraryObj);
    }

    getVideoLibraryFilterApply(videoLibraryFilterObj): any {
        return this._http.post(this.videoLibraryURL, videoLibraryFilterObj);
    }
    getQ3DashboardDetails(q3Obj): any {
        return this._http.post(this.q3DashboardURL, q3Obj);
    }

    // internal compare start Indicator 

    getInternalCompareStatIndicator(indicatorObj): any {
        return this._http.post(this.internalCompareStartIndicatiorURL, indicatorObj);
    }
    // internal compare filter start Indicator

    getInternalCompareFilterStatIndicator(filterindicatorObj): any {
        return this._http.post(this.internalCompareFilterStartIndicatiorURL, filterindicatorObj);
    }

    // marketplace comparation start indicator

    getMarketplaceStartIndicator(marketObj): any {
        return this._http.post(this.marketplaceStartIndicator, marketObj);
    }

    private marketplaceStartIndicatorSource = new BehaviorSubject('');
    marketplaceStartIndicatorObserver = this.marketplaceStartIndicatorSource.asObservable();

    onMarketplaceStartIndicatorDetails(isMarketplaceStartIndicator: string) {
        this.marketplaceStartIndicatorSource.next(isMarketplaceStartIndicator);
    }

    // show spinner observer 

    public showSpinnerSource = new BehaviorSubject('');
    showSpinnerObserver = this.showSpinnerSource.asObservable();

    onShowSpinner(isShowSpinner: string) {
        this.showSpinnerSource.next(isShowSpinner);
    }

    // user Management

    //list 
    getUserList(): any {
        return this._http.get(this.userListURL);
    }
    // produts list
    getProductList(): any {
        return this._http.get(this.productsUserURL);
    }

    // get users based on product id
    getProductUserList(productId): any {
        return this._http.get(this.productsUserURL + "/" + productId);
    }

    // // users list 
    // getAllUsersList(productId): any {
    //     return this._http.get(this.allUsersURL + "/" + productId);
    // }

    getAllUsersList(): any {
        return this._http.get(this.allUsersURL);
    }

    // delete/update product user list

    getProductUserUnMap(unMapObj): any {
        return this._http.post(this.unMapUserURL, unMapObj);
    }

    // roles list
    getRoleList(): any {
        return this._http.get(this.roleUserURL);
    }
    // create user
    getCreateUsers(createObj): any {
        return this._http.post(this.createUserURL, createObj);
    }
    // update user
    getUpdateUsers(updateObj): any {
        return this._http.post(this.updateUserURL, updateObj);
    }

    // delete user 
    getDeleteUser(deleteObj): any {
        return this._http.delete(this.deleteUserURL + "/" + deleteObj.userId)
    }

    // cognito user list
    getCognitoUser(cognitoObj): any {
        return this._http.post(this.cognitoUserURL, "");
    }

    getProductMapping(updateObj): any {
        return this._http.post(this.productMappingURL, updateObj);
    }

    getCognitoProduct(): any {
        return this._http.get(this.cognitoProductList);
    }

}
