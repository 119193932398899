<mat-chip-list class="filter-list">
    <ng-container *ngIf="filterType != 'compare'">
        <ng-container *ngIf="filterKeyList.length > 0; else totalChip">
            <ng-container *ngFor="let keyList of filterKeyList">
                <ng-container *ngFor="let list of filterList[keyList]?.valueName; index as i">
                    <mat-chip (removed)="cancelList(i, filterList[keyList].valueId, filterList[keyList].data)">
                        {{list}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="filterType == 'compare'">
        <ng-container *ngIf="filterKeyList.length > 0; else totalChip">
            <ng-container *ngFor="let list of filterKeyList">
                <mat-chip (removed)="cancelCompareList(list)">
                    {{list.attribute_Description}}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-chip-list>
<ng-template #totalChip>
    <mat-chip>Total</mat-chip>
</ng-template>