import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/api-service';
import { ExportService } from '../../../shared/export-service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})

export class SidebarComponent implements OnInit {
  public productList;
  public adsList;
  products: string;
  ads: string;
  sideBarList;
  dashboardDemoDetailsList;
  adObj;
  videopath;
  videoExtention: boolean;
  videoPercentage;
  dashboardName;
  videoGenderPercentage = [];
  videoSingleScore: boolean;
  t = 0;
  q3List: any;

  constructor(
    private apiService: ApiService,
    private exportService: ExportService,
    private router: Router) { }

  async ngOnInit() {
    this.ads = "0";
    let corporateId = localStorage.getItem('corporateId');
    let productDetails = localStorage.getItem('productDetails');
    let roleId = localStorage.getItem('role');
    this.apiService.onShowSpinner("true");

    this.apiService.getProductDetails(productDetails, roleId,
    ).subscribe(
      async (productRes) => {
        await productRes.products.sort(function(a, b) {
          let productA = a.productName.toUpperCase();
          let productB = b.productName.toUpperCase();
          return (productA < productB) ? -1 : (productA > productB) ? 1 : 0;
      });
        this.productList = productRes.products;
        localStorage.setItem('productName', this.productList[0].productName);
        this.products = this.productList[0].productID;
  
     
      console.log("*",this.productList);
        this.ads = this.productList[0].adDetails[0].adID;
        this.productList.filter(
          (x) => {
            if (x.productID === this.products) {
              this.adsList = x.adDetails;
            }
          }
        );
        this.adsList.filter(
          (x) => {
            if (x.adID === this.ads) {
              this.adObj = x;
              localStorage.setItem('adName', this.adObj.adName);
            }
          });

        if (this.router.url === "/dashboard") {
          this.dashboardName = "DB1";
          this.onDashboardLeftMenu(this.adObj);
          this.apiService.onDashboardDetails(this.sideBarList);
        } else if (this.router.url === "/dashboard/filterDashboard") {
          this.dashboardName = "DB2";
          this.onDashboardDemoMenu(this.adObj, this.products, this.ads);
          this.apiService.onDashboardDemoDetails(this.dashboardDemoDetailsList);
        } else if (this.router.url === "/dashboard/Verbatim") {
          this.dashboardName = "DB11";
          this.onDashboardQ3Menu(this.adObj);
        }
      });
  }
  onSelectProduct() {
    this.productList.filter(
      (x) => {
        if (x.productID === this.products) {
          this.adsList = x.adDetails;
          this.ads = this.adsList[0].adID;
          this.adObj = this.adsList[0];
          localStorage.setItem('productName', x.productName);
          localStorage.setItem('adName', this.adObj.adName);
          if (this.router.url === "/dashboard") {
            this.dashboardName = "DB1";
            this.onDashboardLeftMenu(this.adObj);  // new
            this.apiService.onCompareFilterDetails([]);
            this.apiService.onClearAdFilter([]);
          } else if (this.router.url === "/dashboard/filterDashboard") {
            this.dashboardName = "DB2";
            this.onDashboardDemoMenu(this.adObj, this.products, this.ads);
          } else if (this.router.url === "/dashboard/Verbatim") {
            this.dashboardName = "DB11";
            this.onDashboardQ3Menu(this.adObj);
          }
        }
      });
  }
  onSelectAdName() {
    if (this.products && this.ads) {
      this.adsList.filter(
        (x) => {
          if (x.adID === this.ads) {
            this.adObj = x;
            localStorage.setItem('adName', this.adObj.adName);
          }
        });
      if (this.router.url === "/dashboard") {
        this.dashboardName = "DB1";
        this.onDashboardLeftMenu(this.adObj);
        this.apiService.onCompareFilterDetails([]);
        this.apiService.onClearAdFilter([]);
      } else if (this.router.url === "/dashboard/filterDashboard") {
        this.dashboardName = "DB2";
        this.onDashboardDemoMenu(this.adObj, this.products, this.ads);
      } else if (this.router.url === "/dashboard/Verbatim") {
        this.dashboardName = "DB11";
        this.onDashboardQ3Menu(this.adObj);
      }
    }
  }

  onDashboardLeftMenu(adObj) {
    this.apiService.onOverallSampleDetails(adObj.overSampleType);
    localStorage.setItem('adDetails', JSON.stringify(adObj));
    localStorage.setItem('productId', this.products);
    localStorage.setItem('adId', this.ads);

    this.apiService.onShowSpinner("true");
    let dashboardObj = {
      "corporateID": adObj.corporateID,
      "industryID": adObj.industryID,
      "productID": this.products,
      "campaignID": adObj.campaignID,
      "waveID": adObj.waveID,
      "adID": this.ads,
      "statRequired": "true"
    }

    this.apiService.getDashboardDetails(dashboardObj).subscribe(
      (sideBarRes) => {
        this.apiService.onShowSpinner("false");
        this.sideBarList = sideBarRes;
        this.dashboardDemoDetailsList = "";
        if (this.sideBarList) {
          this.videoSingleScore = false;
          this.videoExtention = false;
          this.apiService.onFilterDetails("");
          this.apiService.onDashboardDetails(this.sideBarList);
          if (this.sideBarList?.videoFileName.split('.')[this.sideBarList?.videoFileName.split('.').length-1] === "mp4") {
            this.videoExtention = true;
            this.videoSingleScore = true;
            this.videopath = "assets/video/" + this.sideBarList.videoFileName + "#t=0.9";
            this.apiService.dashboardObserver.subscribe(value => {
              if (value) {
                this.videoPercentage = value["sections"][1].sectionScores[0].score;
              }
            });
          } else {
            this.videoExtention = false;
            this.videoSingleScore = false;
            this.videopath = "assets/video/" + this.sideBarList.videoFileName;
          }
        }
      });
  }

  onDashboardDemoMenu(adObj, productId, adId) {
    localStorage.setItem('adDetails', JSON.stringify(adObj));
    localStorage.setItem('productId', productId);
    localStorage.setItem('adId', adId);
    this.apiService.onShowSpinner("true");
    this.apiService.onOverallSampleDetails(adObj.overSampleType);
    // localStorage.setItem('overSampleType', adObj.overSampleType);
    let dashboardDemoObj = {
      "corporateID": adObj.corporateID,
      "industryID": adObj.industryID,
      "productID": productId,
      "campaignID": adObj.campaignID,
      "waveID": adObj.waveID,
      "adID": adId,
      "categories": ["net3Age", "dmGen"],
      "normRequired": "true"
    }

    this.apiService.getDashboardDemoDetails(dashboardDemoObj).subscribe(
      (dashboardDemoDetails) => {
        this.sideBarList = "";
        this.videoGenderPercentage = [];
        this.apiService.onShowSpinner("false");
        this.dashboardDemoDetailsList = dashboardDemoDetails;
        if (this.dashboardDemoDetailsList) {
          this.videoSingleScore = false;
          this.videoExtention = false;
          this.apiService.onDashboardDemoDetails(this.dashboardDemoDetailsList);
          this.apiService.dashboardDemoObserver.subscribe(value => {
            if (value) {
              this.videoGenderPercentage = [];
              if (value['videoFileName'].split('.')[1] === "mp4") {
                this.videoExtention = true;
                this.videoSingleScore = true;
                this.videopath = "assets/video/" + value['videoFileName'] + "#t=0.9";

                for (let item of value['sections'][1].sectionScores) {
                  this.videoGenderPercentage.push({
                    label: Object.keys(item),
                    data: Object.values(item)
                  });
                }
              } else {
                this.videoExtention = false;
                this.videoSingleScore = false;
                this.videopath = "assets/video/" + this.sideBarList.videoFileName;
              }
            }
          });
        }
      })
  }
  onDashboardQ3Menu(adObj) {
    this.apiService.onOverallSampleDetails(adObj.overSampleType);
    localStorage.setItem('adDetails', JSON.stringify(adObj));
    localStorage.setItem('productId', this.products);
    localStorage.setItem('adId', this.ads);

    this.apiService.onShowSpinner("true");
    let q3Obj = {
      "corporateID": adObj.corporateID,
      "industryID": adObj.industryID,
      "productID": localStorage.getItem('productId'),
      "campaignID": adObj.campaignID,
      "waveID": adObj.waveID,
      "adID": localStorage.getItem('adId'),
      "statRequired": "true"
    };

    this.apiService.getQ3DashboardDetails(q3Obj).subscribe(
      (sideBarRes) => {
        this.apiService.onShowSpinner("false");
        this.q3List = sideBarRes;
        if (this.q3List) {
          this.videoSingleScore = false;
          this.videoExtention = false;
          //this.apiService.onFilterDetails("");
          this.apiService.onVerbatimDetails(this.q3List);
          if (this.q3List?.videoFileName.split('.')[1] === "mp4") {
            this.videoExtention = true;
            this.videoSingleScore = true;
            this.videopath = "assets/video/" + this.q3List.videoFileName + "#t=0.9";
          } else {
            this.videoExtention = false;
            this.videoSingleScore = false;
            this.videopath = "assets/video/" + this.q3List.videoFileName;
          }
        }
      });
  }

  afflixScreenshotToVideo(event) {
    this.exportService.captureVideos(event.target);
  }

  afflixErrorImgToVideo(event) {
    this.exportService.errorVideoImage(event.target);
  }
}