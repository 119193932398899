<section class="dialogCls">
    <mat-dialog-content>
        <div>
            <form *ngIf="this.screenName !== 'DB5' || this.modalName ==='AdFilter'" [formGroup]="filterForm"
                (ngSubmit)="onConfirmClick()">
                <div *ngFor="let item of this.filterList">
                    <div *ngIf="item?.filterType === 'SingleSelect'">
                        <mat-label>{{item?.attributeName}}</mat-label>
                        <br>
                        <mat-radio-group aria-labelledby="Select an option" multiple
                            [formControlName]="item?.attributeID">
                            <mat-radio-button *ngFor="let norm of item?.filterValues"
                                (change)="onFormValueChange($event,item)" [value]="norm.filterId"
                                [disabled]="fieldDisable">
                                {{norm.attribute_Description}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="item?.filterType === 'MultiSelect'">
                        <div *ngIf="item.attributeName !== 'Sample Type'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple [formControlName]="item?.attributeID" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange($event,item)">
                                    <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                        {{filter.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Sample Type'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple [formControlName]="item?.attributeID"
                                    [disabled]="fieldDisable || sampleTypeDisable"
                                    (selectionChange)="onFormValueChange($event,item)">
                                    <mat-option *ngFor="let filter of item.filterValues"
                                        [value]="filter.attribute_Value">
                                        {{filter.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- <div *ngIf="!dashboard2Flag">
                    <div *ngFor="let item of this.filterList">
                        <div *ngIf="item.attributeName === 'Sample Type'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="sampleType"
                                    [disabled]="fieldDisable || sampleTypeDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let gen of item.filterValues" [value]="gen.attribute_Value">
                                        {{gen.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="item.attributeName === 'Gender'">
                            <mat-label>{{item.attributeName}}</mat-label>
                            <br>
                            <mat-radio-group aria-labelledby="Select an option" formControlName="gender">
                                <mat-radio-button *ngFor="let gender of item.filterValues"
                                    (change)="onFormValueChange()" [value]="gender.attribute_Value"
                                    [disabled]="fieldDisable">
                                    {{gender.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="item.attributeName === 'Age'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="age" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let age of item.filterValues" [value]="age.attribute_Value">
                                        {{age.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Generational Groups'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="generationalgroups" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let group of item.filterValues" [value]="group.attribute_Value">
                                        {{group.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Race'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="race" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let race of item.filterValues" [value]="race.attribute_Value">
                                        {{race.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Household Income'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="household" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let houseHold of item.filterValues"
                                        [value]="houseHold.attribute_Value">
                                        {{houseHold.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Education'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple formControlName="education" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange()">
                                    <mat-option *ngFor="let education of item.filterValues"
                                        [value]="education.attribute_Value">
                                        {{education.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="item.attributeName === 'Children in Household'">
                            <mat-label>{{item.attributeName}}</mat-label><br>
                            <mat-radio-group aria-labelledby="Select an option" formControlName="childreninhouse">
                                <mat-radio-button *ngFor="let child of item.filterValues"
                                    [value]="child.attribute_Value" [disabled]="fieldDisable"
                                    (change)="onFormValueChange()">
                                    {{child.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="item.attributeName === 'Employment Status'">
                            <mat-label>{{item.attributeName}}</mat-label><br>
                            <mat-radio-group aria-labelledby="Select an option" formControlName="employmentstatus">
                                <mat-radio-button *ngFor="let userType of item.filterValues"
                                    [value]="userType.attribute_Value" [disabled]="fieldDisable"
                                    (change)="onFormValueChange()">
                                    {{userType.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="item.attributeName === 'Identify Users or Non-Users of Brands'">
                            <mat-label>{{item.attributeName}}</mat-label><br>
                            <mat-radio-group aria-labelledby="Select an option" formControlName="userbrand">
                                <mat-radio-button *ngFor="let userType of item.filterValues"
                                    [value]="userType.attribute_Value" [disabled]="fieldDisable"
                                    (change)="onFormValueChange()">
                                    {{userType.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="item.attributeID === 'Q9000A'">
                            <mat-label>{{item.attributeName}}</mat-label><br>
                            <mat-radio-group aria-labelledby="Select an option" formControlName="republian">
                                <mat-radio-button *ngFor="let userType of item.filterValues"
                                    [value]="userType.attribute_Value" [disabled]="fieldDisable"
                                    (change)="onFormValueChange()">
                                    {{userType.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="dashboard2Flag">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Sample Type</mat-label>
                        <mat-select multiple [formControl]="filterSampleType">
                            <mat-option *ngFor="let filter of filterSampleTypeList" [value]="filter.value">
                                {{filter.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
            <form *ngIf="this.screenName === 'DB5'" [formGroup]="normForm">
                <div *ngIf="modalName === 'NormFilter'">
                    <div *ngFor="let item of this.NormList">
                        <div *ngIf="item?.filterType === 'SingleSelect'">
                            <mat-label>{{item?.attributeName}}</mat-label>
                            <br>
                            <mat-radio-group aria-labelledby="Select an option" [formControlName]="item?.attributeID">
                                <mat-radio-button *ngFor="let norm of item?.filterValues"
                                    (change)="onNormFilterValueChange($event,item)" [value]="norm.attribute_Value"
                                    [disabled]="fieldDisable">
                                    {{norm.attribute_Description}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div *ngIf="item?.filterType === 'MultiSelect'">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>{{item.attributeName}}</mat-label>
                                <mat-select multiple [formControlName]="item?.attributeID" [disabled]="fieldDisable"
                                    (selectionChange)="onFormValueChange($event,item)">
                                    <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                        {{filter.attribute_Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="center">
        <button mat-raised-button color="primary" id="confirmBtn" (click)="onConfirmClick()" tabindex="1"
            *ngIf="confirmButtonText">{{confirmButtonText}}</button>
        <button mat-raised-button id="cancelBtn" (click)="onCancelclick()" tabindex="-1"
            *ngIf="cancelButtonText">{{cancelButtonText}}</button>
    </mat-dialog-actions>
</section>