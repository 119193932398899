import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommonModule } from '@angular/common';
import { CommonMaterialModule } from '../common-material-modules/common-material.module';
import { BarChartComponent } from './components/widgets/barChart/barChart.component';
import { ChartsModule } from 'ng2-charts';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MultiDoughnutChartComponent } from './components/widgets/multi-doughnut-chart/multi-doughnut-chart.component';
import { MyDoughnutChartComponent } from './components/widgets/my-doughnut-chart/my-doughnut-chart.component';
import { StackedBarChartComponent } from './components/widgets/stacked-bar-chart/stacked-bar-chart.component';
import { FormsModule } from '@angular/forms';
import { GenderMultiDoughnutChart } from './components/widgets/gendermultidoughnut/gendermultidoughnut.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        MultiDoughnutChartComponent,
        MyDoughnutChartComponent,
        BarChartComponent,
        StackedBarChartComponent,
        GenderMultiDoughnutChart],
    imports: [
        CommonMaterialModule,
        CommonModule,
        ChartsModule,
        FormsModule
    ],
    exports: [HeaderComponent, FooterComponent,
        BarChartComponent,
        MyDoughnutChartComponent, SidebarComponent,
        MultiDoughnutChartComponent, StackedBarChartComponent, GenderMultiDoughnutChart]
})

export class HomeModule {
    constructor() { }
}