import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ApiService } from "src/app/shared/api-service";

@Component({
  selector: "app-normFilter",
  templateUrl: "./normFilter.component.html",
  styleUrls: ["./normFilter.component.scss"]
})

export class NormFilterComponent implements OnInit {

  // variables declarations

  normfilterForm: FormGroup;
  normbehaviour;
  normBehaviourList;
  normFilterList;
  normFormValue: any;


  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.normFormValue = this.apiService.lastFormValues;
    this.normfilterForm = this.formBuilder.group({});
    this.normfilterForm.addControl('normbehaviour', new FormControl(this.normFormValue['normbehaviour'] ?
      this.normFormValue['normbehaviour'] : []));
    this.apiService.getInternalNormDetails("DB5").subscribe(
      (internalRes) => {
        if (internalRes) {
          this.normFilterList = internalRes.NormFilter;
          this.normBehaviourList = internalRes.BehaviorFilter[0].filterValues;
          this.normfilterForm.patchValue({
            normbehaviour: ""
          });
          for (let k = 0; k < this.normFilterList.length; k++) {
            this.normfilterForm.addControl(this.normFilterList[k].attributeID,
              new FormControl(this.normFormValue[this.normFilterList[k].attributeID] ?
                this.normFormValue[this.normFilterList[k].attributeID] : ''));
          }
        }
      });
  }

  onNormValueChange() {

  }

  onNormConfirmClick() {

  }

  onNormClear() {

  }
}
