<div class="row m-2 panel-default">
    <div class="col-lg-6 two-multi-doughnut ">
        <div class="col-lg-6 float-left" *ngIf="genderMultiData6">
            <!-- Male -->
            <div class="col-lg-12">
                <canvas baseChart [datasets]="genderMultiData6" [labels]="genderMultiLabel6"
                    [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                    [colors]="[{backgroundColor: ['#0087CE', '#ffffff'],hoverBackgroundColor: ['#0087CE', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
                </canvas>
                <div class="genderSignImg"><img src="assets/img/male.svg"></div>
                <div class="lable-panel-score" *ngIf="genderMultiRes[0]">
                    <div class="label-position-dnut-one">
                        <div class="age-grp-num-cap">
                            <span> {{genderMultiRes[0]?.data[0]}} </span>
                        </div>
                        <div class="blue-underline">{{genderMultiRes[0]?.label[0]}}</div>
                        <span *ngIf='genderMultiDoughnutScoreHigh[0]!="noScore"'>
                            <span *ngIf="genderMultiDoughnutScoreHigh[0]">
                                <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                            </span>
                            <span *ngIf="!genderMultiDoughnutScoreHigh[0]">
                                <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                            </span>
                        </span>
                    </div>

                    <div class="divider-two-dough"></div>
                    <div class="label-position-dnut-two">
                        <div class="age-grp-num-cap">{{genderMultiRes[0]?.data[1]}} </div>
                        <div class="grey-bottom">{{genderMultiRes[0]?.label[1]}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 float-right" *ngIf="genderMultiData1">
            <!-- Female -->
            <div class="col-lg-12">
                <canvas baseChart float="right" [datasets]="genderMultiData1" [labels]="genderMultiLabel1"
                    [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                    [colors]="[{backgroundColor: ['#652D9A', '#ffffff'],hoverBackgroundColor: ['#652D9A', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
                </canvas>
                <div class="genderSignImg"><img src="assets/img/female.svg"></div>
                <div class="lable-panel-score" *ngIf="genderMultiRes[1]">
                    <div class="label-position-dnut-one">
                        <div class="age-grp-num-cap">{{genderMultiRes[1].data[0]}}
                        </div>
                        <div class="purple-underline">{{genderMultiRes[1].label[0]}}</div>
                        <span *ngIf='genderMultiDoughnutScoreHigh[1]!="noScore"'>
                            <span *ngIf="genderMultiDoughnutScoreHigh[1]">
                                <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                            </span>
                            <span *ngIf="!genderMultiDoughnutScoreHigh[1]">
                                <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                            </span>
                        </span>
                    </div>
                    <div class="divider-two-dough"></div>
                    <div class="label-position-dnut-two">
                        <div class="age-grp-num-cap">{{genderMultiRes[1].data[1]}} </div>
                        <div class="grey-bottom">{{genderMultiRes[1].label[1]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 four-multi-doughnut p-0">
        <div class="col-lg-12 first-row-md">
            <div class="col-lg-6 float-left" *ngIf="genderMultiData2">
                <div class="col-lg-8 p-0 float-left chart-panel">
                    <canvas baseChart [datasets]="genderMultiData2" [labels]="genderMultiLabel2"
                        [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                        [colors]="[{backgroundColor: ['#ED7D31', '#ffffff'],hoverBackgroundColor: ['#ED7D31', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">

                    </canvas>
                    <div class="SignImg">
                        <span>Age</span>
                        <p>{{genderMultiRes[2]?.label[0]}}</p>
                    </div>
                </div>
                <div class="col-lg-4 p-0 float-right">
                    <div *ngIf="genderMultiRes[2]">
                        <div class="col-lg-12 lineSpacecls p-0">
                            <div class="age-grp-num-cap">{{genderMultiRes[2]?.data[0]}}
                                <span *ngIf='genderMultiDoughnutScoreHigh[2]!="noScore"'>
                                    <span *ngIf="genderMultiDoughnutScoreHigh[2]">
                                        <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                                    </span>
                                    <span *ngIf="!genderMultiDoughnutScoreHigh[2]">
                                        <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                                    </span>
                                </span>
                            </div>
                            <p class="orange-underline">Score</p>
                        </div>
                        <!-- <hr /> -->
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[2]?.data[1]}}</p>
                            <p class="grey-bottom">{{genderMultiRes[2]?.label[1]}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 float-right" *ngIf="genderMultiData4">
                <div class="col-lg-8 p-0 float-left chart-panel">
                    <canvas baseChart [datasets]="genderMultiData4" [labels]="genderMultiLabel4"
                        [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                        [colors]="[{backgroundColor: ['#ED7D31', '#ffffff'],hoverBackgroundColor: ['#ED7D31', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
                    </canvas>
                    <div class="SignImg">
                        <span>Age</span>
                        <p>{{genderMultiRes[4]?.label[0]}}</p>
                    </div>
                </div>
                <div class="col-lg-4 p-0 float-right">
                    <div *ngIf="genderMultiRes[4]">
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[4]?.data[0]}}
                                <span *ngIf='genderMultiDoughnutScoreHigh[4]!="noScore"'>
                                    <span *ngIf="genderMultiDoughnutScoreHigh[4]">
                                        <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                                    </span>
                                    <span *ngIf="!genderMultiDoughnutScoreHigh[4]">
                                        <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                                    </span>
                                </span>
                            </p>
                            <p class="orange-underline">Score</p>
                        </div>
                        <!-- <hr /> -->
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[4]?.data[1]}}</p>
                            <p class="grey-bottom">{{genderMultiRes[4]?.label[1]}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 second-row-md">
            <div class="col-lg-6 float-left" *ngIf="genderMultiData3">
                <div class="col-lg-8 p-0 float-left chart-panel">
                    <canvas baseChart [datasets]="genderMultiData3" [labels]="genderMultiLabel3"
                        [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                        [colors]="[{backgroundColor: ['#ED7D31', '#ffffff'],hoverBackgroundColor: ['#ED7D31', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
                    </canvas>
                    <div class="SignImg">
                        <span>Age</span>
                        <p>{{genderMultiRes[3]?.label[0]}}</p>
                    </div>
                </div>
                <div class="col-lg-4 p-0 float-right">
                    <div *ngIf="genderMultiRes[3]">
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[3]?.data[0]}}
                                <span *ngIf='genderMultiDoughnutScoreHigh[3]!="noScore"'>
                                    <span *ngIf="genderMultiDoughnutScoreHigh[3]">
                                        <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                                    </span>
                                    <span *ngIf="!genderMultiDoughnutScoreHigh[3]">
                                        <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                                    </span>
                                </span>
                            </p>
                            <p class="orange-underline">Score</p>
                        </div>
                        <!-- <hr /> -->
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[3]?.data[1]}}</p>
                            <p class="grey-bottom">{{genderMultiRes[3]?.label[1]}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 float-right" *ngIf="genderMultiData5">
                <div class="col-lg-8 p-0 float-left chart-panel">
                    <canvas baseChart [datasets]="genderMultiData5" [labels]="genderMultiLabel5"
                        [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                        [colors]="[{backgroundColor: ['#ED7D31', '#ffffff'],hoverBackgroundColor: ['#ED7D31', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
                    </canvas>
                    <div class="SignImg pl-2">
                        <span>Age</span>
                        <p>{{genderMultiRes[5]?.label[0]}}</p>
                    </div>
                </div>
                <div class="col-lg-4 p-0 float-right">
                    <div *ngIf="genderMultiRes[5]">
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[5]?.data[0]}}
                                <span *ngIf='genderMultiDoughnutScoreHigh[5]!="noScore"'>
                                    <span *ngIf="genderMultiDoughnutScoreHigh[5]">
                                        <img src="assets/img/icon_positive.svg" height="25" width="15" alt="">
                                    </span>
                                    <span *ngIf="!genderMultiDoughnutScoreHigh[5]">
                                        <img src="assets/img/icon_negative.svg" height="25" width="15" alt="">
                                    </span>
                                </span>
                            </p>
                            <p class="orange-underline">Score</p>
                        </div>
                        <!-- <hr /> -->
                        <div class="col-lg-12 lineSpacecls p-0">
                            <p class="age-grp-num-cap">{{genderMultiRes[5]?.data[1]}}</p>
                            <p class="grey-bottom">{{genderMultiRes[5]?.label[1]}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>