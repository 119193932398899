import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { ApiService } from "../../../shared/api-service";

@Component({
  selector: "app-adFilter",
  templateUrl: "./adFilter.component.html",
  styleUrls: ["./adFilter.component.scss"]
})

export class AdFilterComponent implements OnInit {

  // common
  @Input() dashboardName;
  formValue: any;
  dashboardfilterForm: FormGroup;
  filterList;
  sampleTypeDisable: boolean;
  formSelectedItems: any = [];
  fieldList = [];
  labelValue;
  selectedFormValues: any = {};
  filterLblList = [];
  fieldDisable: boolean;
  differedFields = {};
  filterApplyList;
  @Input() behavior;
  selectedFilterList = [];

  // internal 
  internalfilterApplyList = [];

  //internal demo 
  internaldemofilterApplyList = [];
  normdemoadfilterApplyList;

  // market place 
  marketplaceApplyList;
  marketplaceLabelValue = [];
  marketAdList = [];
  marketplaceAdStartIndicator = [];

  overallSampleTypeObserverSub$: Subscription;
  filterCancelObserverSub$: Subscription;
  getFilterDetailsSub$: Subscription;
  getFilterApplyDetailsSub$: Subscription;
  getInternalCompareFilterStatIndicatorSub$: Subscription;
  getDashboardDemoApplyFilterSub$: Subscription;
  nominativefilterObserverSub$: Subscription;
  getAdNormFilterApplyDetailsSub$: Subscription;
  nominativeDemofilterObserverSub$: Subscription;
  getNormDemoFilterApplyDetailsSub$: Subscription;
  filterListObserverSub$: Subscription;
  getMarketPlaceFilterApplySub$: Subscription;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formValue = this.apiService.lastFilterFormValues;
    this.dashboardfilterForm = this.formBuilder.group({});
    this.overallSampleTypeObserverSub$ = this.apiService.overallSampleTypeObserver.subscribe(value => {
      if (value) {
        if (value === "disable") {
          this.sampleTypeDisable = true;
          this.dashboardfilterForm.get('Q1')?.setValue([]);
        } else {
          this.sampleTypeDisable = false;
          this.dashboardfilterForm.get('Q1')?.setValue([260]);
        }
      }
    });

    this.filterCancelObserverSub$ = this.apiService.filterCancelObserver.subscribe((cancelList: any) => {
      this.marketplaceAdStartIndicator = [];
      if (Object.keys(cancelList).length > 0) {
        let { value, data } = cancelList;
        this.dashboardfilterForm.get(data.attributeID).setValue(value);
        this.onFormValueChange({ value }, data);
        this.ondashboardConfirmClick();
      }
    });

    this.getFilterDetailsSub$ = this.apiService.getFilterDetails("DB1").subscribe(
      (filterRes) => {
        this.filterList = filterRes;
        for (let k = 0; k < this.filterList.length; k++) {
          this.dashboardfilterForm.addControl(this.filterList[k].attributeID,
            new FormControl(this.formValue[this.filterList[k].attributeID] ? this.formValue[this.filterList[k].attributeID] : []));
        }
      });
    this.getFilterDetailsSub$ = this.apiService.getFilterDetails("DB11").subscribe(
      (filterRes) => {
        this.filterList = filterRes;
        for (let k = 0; k < this.filterList.length; k++) {
          this.dashboardfilterForm.addControl(this.filterList[k].attributeID,
            new FormControl(this.formValue[this.filterList[k].attributeID] ? this.formValue[this.filterList[k].attributeID] : []));
        }
      });
    this.apiService.clearAdFilterObserver.subscribe( // new
      (result) => {
        this.onClear();
      }
    )
  }

  onFormValueChange(event, data) {
    let seletedValue = event.value;
    let isObject = true;
    this.fieldList.push(data);
    if (this.selectedFormValues[data.attributeID]) {
      delete this.selectedFormValues[data.attributeID];
    }
    if (!seletedValue || (typeof (seletedValue) === 'object' && seletedValue.length === 0)) {
      return;
    }
    if ((seletedValue && typeof (seletedValue) !== 'object')) {
      seletedValue = [event.value];
    }
    this.selectedFormValues[data.attributeID] = {
      valueId: seletedValue,
      valueName: [],
      data
    };
    const filteredValues = this.filterList.filter(key => key.attributeID === data.attributeID)
      .map(key => key.filterValues)[0];

    seletedValue.forEach(selValue => {
      this.selectedFormValues[data.attributeID].valueName.
        push(filteredValues.filter(val => selValue === val.filterId).map(val => val.attribute_Description)[0]);
    });
    this.fieldDisable = Object.keys(this.selectedFormValues).length >= 3;
  }

  ondashboardConfirmClick() {
    const tempList = {};
    this.fieldList = [...new Set(this.fieldList)];
    let tempFieldList = JSON.parse(JSON.stringify(this.fieldList));
    this.differedFields = {};

    this.fieldList.forEach((field, fieldIndex) => {
      field.filterValues.forEach((value, valueIndex) => {
        if (field.attributeID !== value.attributeID) {
          if (!tempList[value.attributeID]) {
            this.differedFields[field.attributeID] = value.attributeID;
            tempList[value.attributeID] = Object.assign({}, field);
            tempList[value.attributeID].attributeID = value.attributeID;
            tempList[value.attributeID].filterValues = [];
          }
          tempList[value.attributeID].filterValues.push(value);
          delete tempFieldList[fieldIndex].filterValues[valueIndex];
        }
        if (valueIndex === field.filterValues.length - 1) {
          tempFieldList[fieldIndex].filterValues = tempFieldList[fieldIndex].filterValues.filter((val) => val);
        }
      });
    });

    tempFieldList = [...tempFieldList, ...Object.values(tempList)];
    let formValue = Object.entries(this.dashboardfilterForm.value);
    let filterFormList = [];
    let cancelFilterFormList = [];

    for (let i = 0; i < formValue.length; i++) {
      if (formValue[i][1] != "") {
        for (let m = 0; m < tempFieldList.length; m++) {
          let filterlabVal;
          let filtervalList = [];
          if (formValue[i][0] === tempFieldList[m].attributeID ||
            (Object.values(this.differedFields).indexOf(tempFieldList[m].attributeID) > -1 &&
              Object.keys(this.differedFields).indexOf(formValue[i][0]) > -1)) {
            tempFieldList[m].filterValues.filter(
              (x) => {
                if (formValue[i][1]['length'] === undefined) {
                  if (x.filterId === formValue[i][1]) {
                    filterlabVal += x.attribute_Value + ",";
                    filtervalList.push({
                      "attribute_Description": x.attribute_Description,
                      "attribute_Value": x.attribute_Value,
                      "filterName": tempFieldList[m].attributeID,
                      "filterType": tempFieldList[m].filterType,
                      "filterId": x.filterId
                    });
                  }
                } else if (formValue[i][1]['length'] !== undefined) {
                  for (let k = 0; k < formValue[i][1]['length']; k++) {
                    if (x.filterId === formValue[i][1][k]) {
                      filterlabVal += x.attribute_Value + ",";
                      filtervalList.push({
                        "attribute_Description": x.attribute_Description,
                        "attribute_Value": x.attribute_Value,
                        "filterName": tempFieldList[m].attributeID,
                        "filterType": tempFieldList[m].filterType,
                        "filterId": x.filterId
                      });
                    }
                  }
                }
              });
            if (filterlabVal) {
              filterFormList.push({
                "filterName": tempFieldList[m].attributeID,
                "filterType": tempFieldList[m].filterType,
                "filterVal": filterlabVal.slice(9).slice(0, -1),
              });

              cancelFilterFormList.push({
                "filterName": tempFieldList[m].attributeID,
                "filterType": tempFieldList[m].filterType,
                "filterVal": filterlabVal.slice(9).slice(0, -1),
                "filtervalList": filtervalList
              });
            }
          }
        }
      }
    }
    this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
    this.apiService.onFilterDetails(this.selectedFormValues);
    if (this.dashboardName === "DB1") {
      this.apiService.onShowSpinner("true");
      let adObj = JSON.parse(localStorage.getItem('adDetails'));
      let filterApplyObj =
      {
        "corporateID": adObj.corporateID,
        "industryID": adObj.industryID,
        "productID": localStorage.getItem('productId'),
        "campaignID": adObj.campaignID,
        "waveID": adObj.waveID,
        "adID": localStorage.getItem('adId'),
        "filters": filterFormList,
        "categories": ["net3Age", "dmGen"],
        "normRequired": "true",
        "statRequired": "true"
      }
      this.getFilterApplyDetailsSub$ = this.apiService.getFilterApplyDetails(filterApplyObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (this.filterApplyList) {
            this.apiService.onDashboardDetails(this.filterApplyList);
            this.apiService.onShowSpinner("false");

          } else { // new
            let dashboardObj = {
              "corporateID": adObj.corporateID,
              "industryID": adObj.industryID,
              "productID": localStorage.getItem('productId'),
              "campaignID": adObj.campaignID,
              "waveID": adObj.waveID,
              "adID": adObj.adID,
              "statRequired": "true"
            }
            this.apiService.getDashboardDetails(dashboardObj).subscribe(
              (result) => {
                this.filterApplyList = result;
                this.apiService.onDashboardDetails(this.filterApplyList);
                this.apiService.onCompareFilterDetails([]);
                this.onClear();
                this.apiService.onShowSpinner("false");

              }
            )
          }

        });

    } else if (this.dashboardName === "DB3") {
      this.internalfilterApplyList = [];
      let adObj = JSON.parse(localStorage.getItem('listadObjInternal'));

      let listFilterAds = [];
      for (let m = 0; m < adObj.length; m++) {
        listFilterAds.push({
          "corporateID": adObj[m].corporateID,
          "industryID": adObj[m].industryID,
          "productID": adObj[m].productID,
          "campaignID": adObj[m].campaignID,
          "waveID": adObj[m].waveID,
          "adID": adObj[m].adID,
          "filters": filterFormList,
          "categories": [],
          "normRequired": "true",
          "statRequired": "true"
        });
      }

      let filterObj = {
        "adCompareList": listFilterAds
      }
      this.getInternalCompareFilterStatIndicatorSub$ = this.apiService.getInternalCompareFilterStatIndicator(filterObj).subscribe(
        (filterApplyRes) => {
          this.internalfilterApplyList = filterApplyRes;
          this.apiService.onInternalCompareDetails(JSON.stringify(this.internalfilterApplyList));
        });
    }
    else if (this.dashboardName === "Compare Ad Filter") {
      this.apiService.onInternalDemoCompareFilterDetails(this.labelValue?.slice(0, -2));
      let demoadObj = JSON.parse(localStorage.getItem('listdemoadObjInternal'));
      this.internalfilterApplyList = [];
      this.internaldemofilterApplyList = [];
      this.apiService.internalDemoFilterObj = [];
      this.apiService.onCompareAdFilterList(JSON.stringify(filterFormList));
      for (let m = 0; m < demoadObj.length; m++) {

        let demoInternalFilterObj = {
          "corporateID": demoadObj[m].corporateID,
          "industryID": demoadObj[m].industryID,
          "productID": demoadObj[m].productID,
          "campaignID": demoadObj[m].campaignID,
          "waveID": demoadObj[m].waveID,
          "adID": demoadObj[m].adID,
          "categories": ["net2Age"],
          "normRequired": "false",
          "filters": filterFormList
        };

        this.apiService.internalDemoFilterObj.push({
          productID: parseInt(demoadObj[m].productID),
          productName: demoadObj[m].productName,
          adName: demoadObj[m].adName,
          adID: demoadObj[m].adID,
          filterFormList: filterFormList
        });
        this.getDashboardDemoApplyFilterSub$ = this.apiService.getDashboardDemoApplyFilter(demoInternalFilterObj).subscribe(
          (filterApplyRes) => {
            if (filterApplyRes != null) {
              this.internaldemofilterApplyList.push(filterApplyRes);
              this.apiService.onInternalDemoCompareDetails(JSON.stringify(this.internaldemofilterApplyList));
            }
          });
      }
    }
    else if (this.dashboardName === "Ad Filter") {
      this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
      this.apiService.onNormAdFilterDetails(this.labelValue?.slice(0, -2));
      let normadObj = JSON.parse(localStorage.getItem('internalNormAdObj'));
      let normFilterFormList = [];

      this.nominativefilterObserverSub$ = this.apiService.nominativefilterObserver.subscribe(
        (value) => {
          if (value) {
            normFilterFormList = JSON.parse(value);
          }
        });

      let filterApplyObj =
      {
        "corporateID": normadObj.corporateID,
        "industryID": normadObj.industryID,
        "productID": normadObj.productID,
        "campaignID": normadObj.campaignID,
        "waveID": normadObj.waveID,
        "adID": normadObj.adID,
        "behaviour": this.behavior,
        "filters": normFilterFormList,
        "adFilters": filterFormList,
        "categories": [],
        "statRequired": "true"
      }

      this.getAdNormFilterApplyDetailsSub$ = this.apiService.getAdNormFilterApplyDetails(filterApplyObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (this.filterApplyList) {
            this.apiService.onAdNormFilter(this.filterApplyList);
          }
        });
    } else if (this.dashboardName === "Norm Demo Ad Filter") {
      this.apiService.lastFilterFormValues = this.dashboardfilterForm.value;
      this.apiService.onNormDemoAdFilterDetails(this.labelValue?.slice(0, -2));
      let normadObj = JSON.parse(localStorage.getItem('internalNormDemoAdObj'));
      let normdemoFilterFormList = [];

      this.nominativeDemofilterObserverSub$ = this.apiService.nominativeDemofilterObserver.subscribe(
        (value) => {
          if (value) {
            normdemoFilterFormList = JSON.parse(value);
          }
        });

      this.apiService.normdemoadFormList = filterFormList;
      let demofilterApplyObj =
      {
        "corporateID": normadObj.corporateID,
        "industryID": normadObj.industryID,
        "productID": normadObj.productID,
        "campaignID": normadObj.campaignID,
        "waveID": normadObj.waveID,
        "adID": normadObj.adID,
        "behaviour": this.behavior,
        "normFilters": normdemoFilterFormList,
        "filters": filterFormList,
        "categories": ["net2Age"],
        "normRequired": "true",
        "screenType": "internal",
      }

      this.getNormDemoFilterApplyDetailsSub$ = this.apiService.getNormDemoFilterApplyDetails(demofilterApplyObj).subscribe(
        (filterApplyRes) => {
          this.normdemoadfilterApplyList = filterApplyRes;
          if (this.normdemoadfilterApplyList) {
            this.apiService.onAdNormDemoFilter(this.normdemoadfilterApplyList);
          }
        });
    } else if (this.dashboardName === "MarketPlace Ad Filter") {

      let adStartIndicator = this.apiService.marketStartIndicator;

      for (let w = 0; w < adStartIndicator.length; w++) {
        this.marketplaceAdStartIndicator.push({
          filters: adStartIndicator[w].filters.concat(filterFormList),
          behaviour: this.behavior,
          title: adStartIndicator[w].title
        })
      }
      let marketStartObj = {
        "marketCompareList": this.marketplaceAdStartIndicator
      }
      this.apiService.getMarketplaceStartIndicator(marketStartObj).subscribe(
        (value) => {
          if (value) {
            this.apiService.onMarketplaceStartIndicatorDetails(value);
          }
        });
    } else if (this.dashboardName === "DB11") {
      this.apiService.onShowSpinner("true");
      let adObj = JSON.parse(localStorage.getItem('adDetails'));
      let filterApplyObj =
      {
        "corporateID": adObj.corporateID,
        "industryID": adObj.industryID,
        "productID": localStorage.getItem('productId'),
        "campaignID": adObj.campaignID,
        "waveID": adObj.waveID,
        "adID": localStorage.getItem('adId'),
        "filters": filterFormList,
        "categories": ["net3Age", "dmGen"],
        "normRequired": "true",
        "statRequired": "true"
      }
      this.getFilterApplyDetailsSub$ = this.apiService.getQ3DashboardDetails(filterApplyObj).subscribe(
        (filterApplyRes) => {
          this.filterApplyList = filterApplyRes;
          if (this.filterApplyList) {
            this.apiService.onVerbatimDetails(this.filterApplyList);
            this.apiService.onShowSpinner("false");
          }
        });
    }
  }

  onClear() {
    this.labelValue = "";
    this.fieldDisable = false;
    let clearformValue = Object.keys(this.dashboardfilterForm.controls);
    for (let p = 0; p < clearformValue.length; p++) {
      this.dashboardfilterForm.get(clearformValue[p]).setValue('');
    }
    if (this.dashboardName === "DB1") {
      this.selectedFormValues = [];
      this.internalfilterApplyList = [];
      this.apiService.onInternalCompareAdFilterDetails([]);
    }
    else if (this.dashboardName === 'DB3') {
      this.selectedFormValues = [];
      this.internalfilterApplyList = [];
      this.apiService.onInternalDemoCompareAdFilterDetails([]);
    }
    else if (this.dashboardName === 'Norm Demo Ad Filter') {
      this.selectedFormValues = [];
      this.internaldemofilterApplyList = [];
      this.apiService.onInternalCompareAdFilterDetails([]);
    }
    else if (this.dashboardName === 'Ad Filter') {
      this.selectedFormValues = [];
      this.internaldemofilterApplyList = [];
      this.apiService.onInternalCompareAdFilterCloseIndexDetails([]);
    }
    else if (this.dashboardName === 'MarketPlace Ad Filter') {
      this.selectedFormValues = [];
      this.internaldemofilterApplyList = [];
      this.apiService.onInternalCompareAdFilterCloseIndexDetails([]);
    }
    else if (this.dashboardName === 'Compare Ad Filter') {
      this.selectedFormValues = [];
      this.internaldemofilterApplyList = [];
      this.apiService.onInternalCompareAdFilterCloseIndexDetails([]);
    }
  }

  ngOnDestroy() {
    this.overallSampleTypeObserverSub$ && this.overallSampleTypeObserverSub$.unsubscribe();
    this.filterCancelObserverSub$ && this.filterCancelObserverSub$.unsubscribe();
    this.getFilterDetailsSub$ && this.getFilterDetailsSub$.unsubscribe();
    this.getFilterApplyDetailsSub$ && this.getFilterApplyDetailsSub$.unsubscribe();
    this.getInternalCompareFilterStatIndicatorSub$ && this.getInternalCompareFilterStatIndicatorSub$.unsubscribe();
    this.getDashboardDemoApplyFilterSub$ && this.getDashboardDemoApplyFilterSub$.unsubscribe();
    this.nominativefilterObserverSub$ && this.nominativefilterObserverSub$.unsubscribe();
    this.getAdNormFilterApplyDetailsSub$ && this.getAdNormFilterApplyDetailsSub$.unsubscribe();
    this.nominativeDemofilterObserverSub$ && this.nominativeDemofilterObserverSub$.unsubscribe();
    this.getNormDemoFilterApplyDetailsSub$ && this.getNormDemoFilterApplyDetailsSub$.unsubscribe();
    this.filterListObserverSub$ && this.filterListObserverSub$.unsubscribe();
    this.getMarketPlaceFilterApplySub$ && this.getMarketPlaceFilterApplySub$.unsubscribe();
    this.apiService.lastFilterFormValues = {};
    this.apiService.onInternalCompareAdFilterDetails([]);
    this.apiService.onInternalDemoCompareAdFilterDetails([]);
    this.apiService.onInternalCompareAdFilterCloseIndexDetails([]);
    this.apiService.onFilterCancelDetails({});
    this.apiService.onOverallSampleDetails('');
  }
}
