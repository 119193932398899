<div class="sidebar">
    <div class="brands-block">
        <p>Please select an ad below to begin using the portal.</p>
        <div class="left-menu">
            <label>Products</label>
            <mat-form-field class="w-100" appearance="outline">
                <mat-select [(ngModel)]="products" name="product" (selectionChange)="onSelectProduct()">
                    <mat-option value="0">Please Select</mat-option>
                    <mat-option *ngFor="let item of productList" [value]="item?.productID">
                        {{item?.productName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <label>Ad Name</label>
            <mat-form-field class="w-100" appearance="outline">
                <mat-select [(ngModel)]="ads" name="ads" (selectionChange)="onSelectAdName()">
                    <mat-option value="0">Please Select AdName</mat-option>
                    <mat-option *ngFor="let item of adsList" [value]="item?.adID">
                        {{item?.adName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="sideBarList">
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Product : </div>
                <div class="sideBarDataCls">{{sideBarList?.productName}} </div>
            </div>
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Campaign : </div>
                <div class="sideBarDataCls">{{sideBarList?.campaignName}} </div>
            </div>
            <div class="borderSidebar">
                <div class="sideBarLabelCls">Test date : </div>
                <div class="sideBarDataCls">{{sideBarList?.testDate}}</div>
            </div>
            <div class="mainCommunicationLabelCls">Main Communication Objectives : </div>
            <div class="sideBarDataCls" *ngFor="let label of sideBarList.labels;let indexOfelement=index">
                #{{indexOfelement+1}} {{label}}
            </div>
            <div class="videoCls mt-4">
                <div *ngIf="videoExtention">
                    <canvas id="canvas" hidden></canvas>
                    <video controls="controls" [src]="videopath" (error)="afflixErrorImgToVideo($event)"
                        (canplaythrough)="afflixScreenshotToVideo($event)" width="100%">
                    </video>
                </div>
                <div *ngIf="!videoExtention">
                    <img [src]="videopath" width="100%">
                </div>
            </div>
            <div class="vid-progress">
                <span>Have seen ad before - <b>{{videoPercentage}}%</b> </span>
            </div>
        </div>
        <div *ngIf="dashboardDemoDetailsList">
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Product : </div>
                <div class="sideBarDataCls">{{dashboardDemoDetailsList?.productName}} </div>
            </div>
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Campaign : </div>
                <div class="sideBarDataCls">{{dashboardDemoDetailsList?.campaignName}} </div>
            </div>
            <div class="borderSidebar">
                <div class="sideBarLabelCls">Test date : </div>
                <div class="sideBarDataCls">{{dashboardDemoDetailsList?.testDate}}</div>
            </div>
            <div class="mainCommunicationLabelCls">Main Communication Objectives : </div>
            <div class="mainCommunicationCls"
                *ngFor="let label of dashboardDemoDetailsList.labels;let indexOfelement=index">
                #{{indexOfelement+1}} {{label}}
            </div>
            <div class="videoCls mt-4">
                <div *ngIf="videoExtention">
                    <canvas id="canvas" hidden></canvas>
                    <video controls="controls" [src]="videopath" preload="metadata"
                        (error)="afflixErrorImgToVideo($event)" (canplaythrough)="afflixScreenshotToVideo($event)"
                        width="300">
                        <source type="video/mp4">
                    </video>
                </div>
                <div *ngIf="!videoExtention">
                    <img [src]="videopath" width="300">
                </div>
            </div>
            <label class="video-label">Have seen ad before</label>
            <div class="score-behavior-main">
                <div class="video-score-grp" *ngFor="let video of videoGenderPercentage">
                    <div><b>{{video.data[0]}}%</b></div>
                    <div>{{video.label[0]}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="q3List">
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Product : </div>
                <div class="sideBarDataCls">{{q3List?.productName}} </div>
            </div>
            <div class="borderSidebar">
                <div class="sideBarLabelCls"> Campaign : </div>
                <div class="sideBarDataCls">{{q3List?.campaignName}} </div>
            </div>
                <div class="sideBarLabelCls">Test date : </div>
                <div class="sideBarDataCls">{{q3List?.testDate}}</div>
            <div class="videoCls mt-4">
                <div *ngIf="videoExtention">
                    <canvas id="canvas" hidden></canvas>
                    <video controls="controls" [src]="videopath" (error)="afflixErrorImgToVideo($event)"
                        (canplaythrough)="afflixScreenshotToVideo($event)" width="100%">
                    </video>
                </div>
                <div *ngIf="!videoExtention">
                    <img [src]="videopath" width="100%">
                </div>
            </div>
            <div class="vid-progress">
                <span>Have seen ad before - <b>{{videoPercentage}}%</b> </span>
            </div>
        </div>
    </div>
</div>