import { Component, Input, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartType, ChartOptions } from 'chart.js';

@Component({
  selector: "gender-multi-doughnut",
  templateUrl: "./gendermultidoughnut.component.html",
  styleUrls: ["./gendermultidoughnut.component.scss"]
})

export class GenderMultiDoughnutChart implements OnInit {

  @Input() genderMultiRes;
  @Input() genderMultiDoughnutScoreHigh;
  @Input() genderMultiData1;
  @Input() genderMultiLabel1;
  @Input() genderMultiData2;
  @Input() genderMultiLabel2;
  @Input() genderMultiData3;
  @Input() genderMultiLabel3;
  @Input() genderMultiData4;
  @Input() genderMultiLabel4;
  @Input() genderMultiData5;
  @Input() genderMultiLabel5;
  @Input() genderMultiData6;
  @Input() genderMultiLabel6;

  doughnutChartType: ChartType = 'doughnut';
  doughnutChartOptions: ChartOptions = {
    // tooltips: {
    //   enabled: false
    // },
    scales: {
      xAxes: [{
        gridLines: {
          display: false, drawBorder: true
        }
        ,
        ticks: {
          display: false
        }
      }
      ],
      yAxes: [{
        gridLines: {
          display: false, drawBorder: true
        }
        ,
        ticks: {
          display: false
        }
      }
      ]
    },
    tooltips: {
      enabled: false
    },
    cutoutPercentage: 70,
    // circumference: 5,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      onClick: null
    },
    plugins: {
      datalabels: {
        display: false
      },
      datasets: [{
        backgroundColor: ['#2196f3', '#4caf50', '#0087CE', '#9B9B9B'],
        borderColor: ['#2196f3', '#4caf50', '#0087CE', '#9B9B9B']
      }]
    }
  };

  constructor(private router: Router,
    private sanitizer: DomSanitizer) { }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };

  randomScalingFactor() {
    return Math.round(Math.random() * 100);
  };

  ngOnInit() { }

}
