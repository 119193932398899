import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import pptxgen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { data } from 'jquery';

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    containerId = 'contentToConvert';
    pptClass = 'ppt-slide';
    filterListClass = 'filterLbl';
    commonignoreElements = ['dropdown-menu', 'filterDiv button', 'norm-filter'];
    table: any;
    Header: string = "Verbatim Comments ";


    generateImage(type: string = 'PNG') { }

    downloadImage(type: string = 'PNG', fileName: string = 'download') { }

    generatePDF(fileName: string = 'download', config, callback) {
        const containerDom = document.getElementById(this.containerId);
        html2canvas(containerDom, {
            ignoreElements: (element) => this.commonignoreElements.some(ignElement => {
                if (typeof ignElement === 'string') { return element.classList.contains(ignElement); }
                else { return false; }
            }),
            onclone: (element) => {
                if (config.indexOf('showProductAdLabels') > -1) {
                    element.querySelector(`.${this.filterListClass}`).parentElement.classList.replace("col-md-8", "col-md-6");
                    element.querySelector(`.${this.filterListClass}`).parentElement.classList.replace("col-md-10", "col-md-8");
                    element.querySelector('.filterDiv').innerHTML = `<div style="text-align:right; margin-right:8px"><h6><b>Product: </b><span>${localStorage.getItem('productName')}</span></h6><h6><b>Ad Name: </b><span>${localStorage.getItem('adName')}</span></h6></div>`
                    element.querySelector('.filterDiv').parentElement.classList.replace("col-md-2", "col-md-4");
                }
            }
        }).then((canvas) => {
            const innerWidth = containerDom.offsetWidth / 6;
            const innerHeight = containerDom.offsetHeight / 6;
            const orientation = innerWidth > innerHeight ? 'l' : 'p';
            const contentDataURL = canvas.toDataURL('image/png');
            const pdf = new jsPDF(orientation, 'mm', [innerWidth, innerHeight]);
            pdf.addImage(contentDataURL, 'PNG', 0, 0, innerWidth, innerHeight);
            pdf.save(`${fileName}.pdf`);
            callback && callback(false);
        });
    }

    async generatePPT(fileName: string = 'download', config, callback) {
        var gOptsTabOpts = { x: 0.5, y: 0.13, colW: [9, 3.33] }; // LAYOUT_WIDE w=13.33
        var gOptsTextL: any = { color: '9F9F9F', margin: 3, border: [null, null, { pt: '1', color: 'CFCFCF' }, null] };
        var gOptsOptsR: any = { color: '9F9F9F', margin: 3, border: [0, 0, { pt: '1', color: 'CFCFCF' }, 0], align: 'right' };
        var gOptsTextR: any = { text: `Ad Name: ${localStorage.getItem('adName')}`, options: gOptsOptsR };
        let masterObject: any = [
            { 'rect': { x: 0.00, y: 6.90, w: '100%', h: 0.6, fill: { color: '00182c' } } },
            {
                'text':
                {
                    options: { x: 0, y: 6.9, w: '100%', h: 0.6, align: 'center', valign: 'middle', color: 'FFFFFF', fontSize: 12 },
                    text: 'Brands For Good - Harris Poll'
                }
            },
            {
                'text':
                {
                    options: { x: 0.6, y: config.indexOf('showProductAdLabels') > -1 ? 0.4 : 0, w: 12, h: 0.6, align: 'center', valign: 'middle', color: '48596F', fontSize: 24 },
                    text: fileName
                }
            }
        ];
        const slideElements = document.getElementsByClassName(this.pptClass);
        if (config.indexOf('noFilter') === -1) {
            const filterElement = document.querySelector(`.${this.filterListClass}`);
            await html2canvas(filterElement as HTMLCanvasElement, {
                ignoreElements: (element) => { return element.classList.contains('mat-icon') },
                onclone: (document) => {
                    let selectedElement = document.querySelector(`.${this.filterListClass}`);
                    selectedElement['style']['backgroundColor'] = '#F1F1F1';
                }
            }).then((canvas) => {
                masterObject.push({ 'image': { x: 0.5, y: 6.45, w: filterElement.clientWidth * 0.010416666666666666, h: filterElement.clientHeight * 0.010416666666666666, data: canvas.toDataURL('image/png') } });;
            });
        }
        const pptx = new pptxgen();
        pptx.title = 'Export';
        pptx.subject = 'PPT Export';
        pptx.author = 'Harris Polls';
        pptx.company = 'Brands For Good';
        pptx.revision = '15';
        pptx.layout = 'LAYOUT_WIDE';
        pptx.defineSlideMaster({
            title: 'MASTER_SLIDE',
            background: { fill: 'F1F1F1' },
            slideNumber: { x: 0.5, y: 7.1, color: 'FFFFFF', fontFace: 'Arial', fontSize: 10 },
            objects: masterObject
        });
        for (let i = 0; i < slideElements.length; i++) {
            await html2canvas(slideElements[i] as HTMLCanvasElement, {
                onclone: (document) => {
                    let selectedElement = document.getElementsByClassName(this.pptClass);
                    selectedElement[i].children[0]['style']['backgroundColor'] = selectedElement[i].classList.contains('ppt-nt-white') ? '#fdf5e9' : 'white';
                }
            }).then((canvas) => {
                const slide = pptx.addSlide({ masterName: 'MASTER_SLIDE', sectionTitle: 'Masters' });
                let innerWidth = slideElements[i].clientWidth * 0.010416666666666666;
                let innerHeight = slideElements[i].clientHeight * 0.010416666666666666;
                if (innerWidth > 13) {
                    innerWidth = 13
                } if (innerHeight > 6) {
                    innerHeight = config.indexOf('showProductAdLabels') > -1 ? 5.5 : 6;
                }
                if (config.indexOf('showProductAdLabels') > -1) {
                    slide.addTable([[{ text: `Product Name: ${localStorage.getItem('productName')}`, options: gOptsTextL }, gOptsTextR]], gOptsTabOpts);
                }
                slide.addImage({ data: canvas.toDataURL('image/png'), x: (13.33 - innerWidth) / 2, y: (7.3 - innerHeight) / 2, w: innerWidth, h: innerHeight });
            });
        }
        pptx.writeFile(`${fileName}.pptx`);
        callback && callback(false);
    }
    public setTableDataSource(dataSource: any) {
        this.table = dataSource;
    }
    public getTableDataSource() {
        return this.table;
    }
    public clearHeader() {
        this.Header = "Verbatim Comments"
    }
    public setColumnHeader(String: any) {
        if (this.Header == "Verbatim Comments") {
            this.Header += "  FilteredBy " + String + " ";
        } else {
            this.Header += "," + String + "  ";
        }

    }
    async generateXLSX(fileName: string = 'download', config, callback) {

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.table);
        var range = XLSX.utils.decode_range(worksheet['!ref']);
         for (var C = range.s.r; C <= range.e.c; ++C) {
             if(C==0){
                var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
                if (!worksheet[address]) continue;
                worksheet[address].v = this.Header;
             }else{
                var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
                if (!worksheet[address]) continue;
                worksheet[address].v = ' ';
             }
             }
        const workbook: XLSX.WorkBook = {
            Sheets: { VerbatimComments: worksheet },
            SheetNames: ['VerbatimComments'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        XLSX.writeFile(workbook, 'Verbatim' + '.xlsx');
        callback && callback(false);
    }
    captureVideos(element) {
        const canvas: any = document.getElementById('canvas');
        const ctx = canvas.getContext('2d');
        let w, h;
        try {
            w = element.videoWidth;
            h = element.videoHeight;
            canvas.width = w;
            canvas.height = h;
            ctx.fillRect(0, 0, w, h);
            ctx.drawImage(element, 0, 0, w, h);
            const a = canvas.toDataURL();
            element.style.backgroundImage = `url(${a})`;
            element.style.backgroundSize = 'cover';
            ctx.clearRect(0, 0, w, h); // clean the canvas
        } catch (e) {
            console.log(e);
        }
    }

    errorVideoImage(element) {
        element.style.backgroundImage = 'url(assets/img/video-unavailable.png)';
        element.style.backgroundSize = 'cover';
    }
}
