<div class="mr-auto ml-auto col-lg-10 col-md-10">
    <div class="compatibility-block">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="compatibility-image">
                    <h1 class="display-1 font-weight-bold mt-5 gray">:(</h1>                    
                    <h4 class="mt-5">Oh snap! you are using <span class="gray">{{device}}</span> that we are not supported yet.</h4>
                    <h4 class="mt-5">Please use desktop.</h4>
                    <img class="mb-5" src="assets/img/compatibility.svg" alt="desktop">
                </div>
            </div>
        </div>
    </div>
    <div class=" my-5 text-center">
        <span>Partners with: <span class="ml-3"><img src="assets/img/harris-logo.png" alt="image"
                    width="180"></span></span>
    </div>
</div>