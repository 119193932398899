import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'BfgADSurveyDashboard-ui';
  url = '';
  items = [];
  constructor(
    public router: Router,
    private http: HttpClient,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService
  ) {
    this.matIconRegistry.addSvgIcon(
      `download-circle`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/download-icon.svg')
    );
    this.http.get('').toPromise().then(data => {
      for (let key in data)
        if (data.hasOwnProperty(key))
          this.items.push(data[key]);
    });
    this.checkCompatibility();
  }

  checkCompatibility() {
    if (!this.deviceService.isDesktop()){
      this.router.navigate(['/compatibility']);
    }
  }
}