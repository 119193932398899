import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CommonMaterialModule } from './common-material-modules/common-material.module';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeModule } from './home/home.module';
import { CommonModule, DatePipe } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { HttpInterceptorService } from './shared/app.interceptor.service';
import { CompatibilityComponent } from './common-material-modules/components/compatibility/compatibility.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    CompatibilityComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HomeModule,
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonMaterialModule,
    ChartsModule,
    NgxPaginationModule
  ],
  exports: [
    CommonMaterialModule,
    ChartsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    DatePipe
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
