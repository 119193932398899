import { Component, Input, OnInit } from "@angular/core";
import { ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: "bar-chart",
  templateUrl: "./barChart.component.html",
  styleUrls: ["./barChart.component.scss"]
})

export class BarChartComponent implements OnInit {

  @Input() barChartMainLabel: string;
  @Input() barChartData: any;
  @Input() barChartLabel: any;
  @Input() barChartOptions: any;
  barChartType: ChartType = 'bar';
  barChartPlugins = [pluginDataLabels];

  constructor() { }

  ngOnInit() { }
}
