import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ChartType, ChartOptions } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Chart from 'chart.js';


@Component({
  selector: "my-doughnut-chart",
  templateUrl: "./my-doughnut-chart.component.html",
  styleUrls: ["./my-doughnut-chart.component.scss"]
})

export class MyDoughnutChartComponent implements OnInit {

  // variable declarations
  @Input() doughnutChartMainLabel;
  @Input() doughnutChartLabels1;
  @Input() doughnutChartData1;
  @Input() doughnutChartLabels2;
  @Input() doughnutChartData2;
  @Input() doughnutScoreHigh;

  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 80,
    tooltips: {
      enabled: false
    },
    legend: { display: false },
    plugins: {
      tooltips: {
        enabled: false
      },
      datalabels: {
        display: false,
      },
    }
  };


  constructor(private router: Router,
    private sanitizer: DomSanitizer) { }


  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };
  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  };

  ngOnInit(): void { }
}