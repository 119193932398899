import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../shared/api-service';

@Component({
  selector: 'app-compare-filter',
  templateUrl: './compare-filter.component.html',
  styleUrls: ['./compare-filter.component.scss']
})
export class CompareFilterComponent implements OnInit {
  panelOpenState = false;

  // common
  @Input() dashboardName;
  @Input() behavior;
  dashboardfilterForm: FormGroup;
  @ViewChild('filterform', { static: true }) filterForm;
  formValue: any;
  fieldList = [];
  differedFields = {};

  // dashboard1
  filterList: any = [];
  sampleTypeDisable: boolean;
  fieldDisable: boolean;
  formSelectedItems: any = [];
  labelValue;
  filterApplyList;
  customTitle = new FormControl('', [Validators.required]);

  // dashboard 2
  filterSampleType = new FormControl('');
  filterSampleTypeList = [{
    label: 'Gen Pop',
    value: 1
  },
  {
    label: 'Overall Sample',
    value: 2
  }];
  sampleTypefilterValue;
  demofilterApplyList;
  demoLabelValue;

  // internal 
  internalfilterApplyList = [];

  //internal demo 
  internaldemofilterApplyList = [];

  // internal norm 
  normfilterApplyList;
  normbehaviour;
  normBehaviourList;

  //internal norm demo
  normdemofilterApplyList;
  normdemoadfilterApplyList;

  // market place 
  marketplaceApplyList;
  marketplaceLabelValue = [];
  marketAdList = [];
  adMarketLabelList = [];
  matcheckboxList = [];

  // internal ad screen
  internalProductList = [];
  internalAdsList = [];
  listOfAds;
  adsDisable;
  internalAdObj;

  constructor(private apiService: ApiService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {

    this.apiService.internalAdsListObserver.subscribe(
      (value) => {
        if (value) {
          let adsValue = [];
          this.listOfAds = JSON.parse(value);
          for (let t = 0; t < this.listOfAds.length; t++) {
            adsValue.push(this.listOfAds[t].adID);
          }

          this.dashboardfilterForm.get('ads').setValue(adsValue);
          if (this.listOfAds.length >= 3) {
            this.adsDisable = true;
          } else {
            this.adsDisable = false;
          }
        }
      });

    this.apiService.compareFilterCancelObserver.subscribe((data: any) => {
      if (Object.keys(data).length) {
        if (this.dashboardfilterForm) {
          this.dashboardfilterForm.get(data.filterId.toString()).setValue('');
        }
        this.matcheckboxChange({ checked: false }, data);
        this.ondashboardConfirmClick();
      }
    });

    if (this.dashboardName === 'MarketPlace Filter' || this.dashboardName === 'Video Library Filter') {
      this.matcheckboxList = [];
      this.dashboardfilterForm = this.formBuilder.group({});
      if (this.dashboardName === 'Video Library Filter') {
        const roleId = localStorage.getItem('role');
        let productDetails = localStorage.getItem('productDetails');
        this.apiService.getProductDetails(productDetails, roleId).subscribe(
          (productRes) => {
            const products = productRes.products.map((pro) => {
              this.dashboardfilterForm.addControl(pro.productID.toString(),
                new FormControl(''));
              return Object.assign({}, pro, {
                attributeID: "Q201", attribute_Description: pro.productName,
                attribute_Value: pro.productID.toString(), filterId: pro.productID
              });
            })
            this.filterList.unshift({ attributeID: 'Q201', attributeName: 'Product', filterValues: products });
          });
      } else {
        this.dashboardfilterForm.addControl('customTitle', this.customTitle);
      }
      this.apiService.getInternalNormDetails('DB5').subscribe(
        (normMarketRes) => {
          this.filterList.push(...normMarketRes.NormFilter);
          this.filterList.filter(
            (item) => {
              for (let p = 0; p < item.filterValues.length; p++) {
                this.dashboardfilterForm.addControl(item.filterValues[p].filterId,
                  new FormControl(''));
              }
            });
        });
    } else if (this.dashboardName === 'Internal Ad Filter' || this.dashboardName === 'Compare Demo Filter') {
      this.formValue = this.apiService.lastFormValues;
      this.dashboardfilterForm = this.formBuilder.group({});
      this.dashboardfilterForm.addControl('product', new FormControl(this.formValue['product'] ? this.formValue['product'] : []));
      this.dashboardfilterForm.addControl('ads', new FormControl(this.formValue['ads'] ? this.formValue['ads'] : []));
      const roleId = localStorage.getItem('role');
      let productDetails = localStorage.getItem('productDetails');
      this.apiService.getProductDetails(productDetails, roleId).subscribe(
        (productRes) => {
          this.internalProductList = productRes.products;
          if (Object.keys(this.formValue).length > 0) {
            this.dashboardfilterForm.patchValue(this.formValue);
            this.onInternalProduct({ value: this.formValue.product });
            this.onInternalAdName({ value: this.formValue.ads });
          }
        });
      this.apiService.internalcompareadfilterCloseIndexObserver.subscribe(
        (item: any) => {
          if (Object.keys(item).length > 0) {
            const productValue = this.dashboardfilterForm.value.product;
            const adsValue = this.dashboardfilterForm.value.ads;
            if (productValue.indexOf(item.productID) !== -1) {
              productValue.splice(productValue.indexOf(item.productID), 1);
              adsValue.splice(adsValue.indexOf(item.adID), 1);
              this.dashboardfilterForm.patchValue({
                product: productValue,
                ads: adsValue
              });
              this.onInternalProduct({ value: productValue });
              this.onInternalAdName({ value: adsValue });
            }
          }
        });
    }
  }

  ondashboardConfirmClick() {
    const tempList = {};
    this.fieldList = [...new Set(this.fieldList)];
    let tempFieldList = JSON.parse(JSON.stringify(this.fieldList));
    this.differedFields = {};

    this.fieldList.forEach((field, fieldIndex) => {
      field.filterValues.forEach((value, valueIndex) => {
        if (field.attributeID !== value.attributeID) {
          if (!tempList[value.attributeID]) {
            this.differedFields[field.attributeID] = value.attributeID;
            tempList[value.attributeID] = Object.assign({}, field);
            tempList[value.attributeID].attributeID = value.attributeID;
            tempList[value.attributeID].filterValues = [];
          }
          tempList[value.attributeID].filterValues.push(value);
          delete tempFieldList[fieldIndex].filterValues[valueIndex];
        }
        if (valueIndex === field.filterValues.length - 1) {
          tempFieldList[fieldIndex].filterValues = tempFieldList[fieldIndex].filterValues.filter((val) => val);
        }
      });
    });

    tempFieldList = [...tempFieldList, ...Object.values(tempList)];
    if (this.dashboardName === 'MarketPlace Filter') {
      let formValue = Object.entries(this.dashboardfilterForm.value);
      let filterFormList = [];
      this.marketplaceLabelValue = [];

      this.apiService.filterListObserver.subscribe(
        (value) => {
          if (value) {
            if (value != '[]') {
              this.marketAdList = JSON.parse(value);
            }
          }
        });

      for (let t = 0; t < this.filterList.length; t++) {
        let marketplaceValueList;
        for (let r = 0; r < this.matcheckboxList.length; r++) {
          if (this.matcheckboxList[r].attributeID === this.filterList[t].attributeID) {
            marketplaceValueList += this.matcheckboxList[r].attribute_Value.toString() + ',';
            this.marketplaceLabelValue.push(this.matcheckboxList[r].attribute_Description);
          }
        }
        if (marketplaceValueList) {
          filterFormList.push({
            'filterName': this.filterList[t].attributeID,
            'filterType': 'Multi Select',
            'filterVal': marketplaceValueList.slice(9).slice(0, -1)
          });

        }
      }

      this.apiService.marketplaceFormList.push({
        filterFormList: filterFormList,
        title: this.customTitle.value
      });

      this.adMarketLabelList.push(this.marketplaceLabelValue);
      localStorage.setItem('admarketplaceLabelValue', JSON.stringify(this.adMarketLabelList))
      this.apiService.onMarketplaceLabelDetails(JSON.stringify(this.marketplaceLabelValue));
      const category = this.dashboardName === 'MarketPlace Gender Filter' ? { categories: ['dmGen'] }
        : this.dashboardName === 'MarketPlace Age Filter' ? { categories: ['net2Age'] } : {};
      let marketFilterObj = {
        'filters': this.apiService.marketpaceadFormList.length > 0 ? filterFormList.concat(this.apiService.marketpaceadFormList) : filterFormList,
        'behaviour': this.behavior,
        'title': this.customTitle.value,
        ...category
      }
      if (this.customTitle.valid) {
        if (filterFormList.length > 0) {
          this.apiService.getMarketPlaceFilterApply(marketFilterObj).subscribe(
            (marketFilterRes) => {
              this.marketplaceApplyList = marketFilterRes;
              let applyList = {
                marketfilterName: this.marketplaceLabelValue,
                marketfilterList: this.marketplaceApplyList
              }
              if (this.marketplaceApplyList) {
                this.apiService.onMarketplaceFilterDetails(JSON.stringify(applyList));
              }
              this.filterForm.resetForm();
            });
        }
      }
      this.marketAdList.push({
        filterFormList: filterFormList,
        title: this.customTitle.value
      });
      this.apiService.onFilterListMarketplace(JSON.stringify(this.marketAdList));
    } else if (this.dashboardName === 'Video Library Filter') {
      let filterFormList = [];

      for (let t = 0; t < this.filterList.length; t++) {
        let videoLibraryValueList;
        for (let r = 0; r < this.matcheckboxList.length; r++) {
          if (this.matcheckboxList[r].attributeID === this.filterList[t].attributeID) {
            videoLibraryValueList += this.matcheckboxList[r].attribute_Value.toString() + ',';
          }
        }
        if (videoLibraryValueList) {
          filterFormList.push({
            'filterName': this.filterList[t].attributeID,
            'filterType': 'Multi Select',
            'filterVal': videoLibraryValueList.slice(9).slice(0, -1)
          });
        }
      }
      let productDetails = localStorage.getItem('productDetails');
      let roleId = localStorage.getItem('role');
      let videoFilterObj = {
        "corporateID": JSON.parse(productDetails),
        "roleName": roleId,
        "filters": filterFormList
      };

      this.apiService.getVideoLibraryFilterApply(videoFilterObj).subscribe(
        (videofilterList) => {
          if (videofilterList) {
            this.apiService.onVideoLibraryFilterDetails(videofilterList);
          }
        });
      this.apiService.onCompareFilterDetails([...this.matcheckboxList]);
    } else if (this.dashboardName === 'Internal Ad Filter') {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      this.apiService.onInternalCompareAdFilterDetails(this.listOfAds);
    } else if (this.dashboardName === 'Compare Demo Filter') {
      this.apiService.lastFormValues = this.dashboardfilterForm.value;
      this.apiService.onInternalDemoCompareAdFilterDetails(this.listOfAds);
    }

  }

  onInternalProduct(event) {
    let internalProducts = event.value;
    this.internalAdsList = [];
    this.internalProductList.filter(
      (x) => {
        for (let p = 0; p < internalProducts.length; p++) {
          if (x.productID === internalProducts[p]) {
            for (let m = 0; m < x.adDetails.length; m++) {
              this.internalAdsList.push(x.adDetails[m]);
            }
          }
        }
      });
    this.adsDisable = false;
  }

  onInternalAdName(event) {
    let internalAds = event.value;
    this.listOfAds = [];
    for (let l = 0; l < event.value.length; l++) {
      for (let p = 0; p < this.internalAdsList.length; p++) {
        if (this.internalAdsList[p].adID === event.value[l]) {
          this.internalAdObj = this.internalAdsList[p];
          this.listOfAds.push(this.internalAdsList[p]);
        }
      }
    }
    if (internalAds.length >= 3) {
      this.adsDisable = true;
    } else {
      this.adsDisable = false;
    }
  }

  onClear() {
    this.labelValue = '';
    this.fieldDisable = false;
    let clearformValue = Object.keys(this.dashboardfilterForm.controls);
    for (let p = 0; p < clearformValue.length; p++) {
      this.dashboardfilterForm.get(clearformValue[p]).setValue('');
    }
    if (this.dashboardName === 'Internal Ad Filter') {
      this.internalAdsList = [];
      this.listOfAds = [];
      this.apiService.onInternalCompareAdFilterDetails([]);
    } else if (this.dashboardName === 'Compare Demo Filter') {
      this.internalAdsList = [];
      this.listOfAds = [];
      this.apiService.onInternalDemoCompareAdFilterDetails([]);
    } else if (this.dashboardName === 'Video Library Filter') {
      this.matcheckboxList = [];
      this.apiService.onVideoLibraryFilterDetails([]);
      this.apiService.onCompareFilterDetails([]);
    }
  }

  onDemoApply() {
    let filteradObj = JSON.parse(localStorage.getItem('adDetails'));
    for (let p = 0; p < this.filterSampleType.value.length; p++) {
      this.sampleTypefilterValue = this.filterSampleType.value[p] + ',';
      this.filterSampleTypeList.filter(
        x => {
          if (x.value === this.filterSampleType.value[p]) {
            this.demoLabelValue += x.label + ',';
          }
        }
      )
    }

    this.apiService.ondashboardDemoFilterDetails(this.demoLabelValue?.slice(0, -1));
    let dashboard2FilterObj = {
      'corporateID': filteradObj.corporateID,
      'industryID': filteradObj.industryID,
      'productID': filteradObj.productID,
      'campaignID': filteradObj.campaignID,
      'waveID': filteradObj.waveID,
      'adID': filteradObj.adID,
      'categories': ['net3Age', 'dmGen'],
      'normRequired': 'true',
      'filters': [
        {
          filterName: 'Q1',
          filterType: 'MultiSelect',
          filterVal: this.sampleTypefilterValue.slice(0, -1)
        }
      ]
    };
    this.apiService.getDashboardDemoApplyFilter(dashboard2FilterObj).subscribe(
      (filterApplyRes) => {
        this.demofilterApplyList = filterApplyRes;
        if (this.demofilterApplyList) {
          this.apiService.onDashboardDemoDetails(this.demofilterApplyList);
          this.filterSampleType.setValue('');
        }
      });
  }

  matcheckboxChange(ev, matItem) {
    if (localStorage.getItem('checkboxList') === '') {
      this.matcheckboxList = [];
    }
    if (ev.checked === true) {
      this.matcheckboxList.push({
        attributeID: matItem.attributeID,
        attribute_Description: matItem.attribute_Description,
        attribute_Value: matItem.attribute_Value,
        filterId: matItem.filterId
      });
      localStorage.setItem('checkboxList', 'data');
    } else {
      this.matcheckboxList = this.matcheckboxList.filter((item) => item.filterId !== matItem.filterId);
    }
  }

  onDemoClear() {
    this.filterSampleType.setValue('');
  }
}
