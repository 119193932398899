<div class="card-body" *ngIf="multiSectionScore">
    <div class="card-heading">{{multiDoughnutChartMainLabel}}</div>
    <div class="row justify-content-center fixed-label">
        <div class="col-md-6 text-center justify-content-center" *ngIf="multiDoughnutChartData1.length>0">
            <div>
                <span class="sub-title-label">
                    {{multiSectionScore[0].behaviourType}}
                </span>
            </div>
            <canvas baseChart id="multi-doughnut-chart-overall-score" width="100%" height="100%"
                [datasets]="multiDoughnutChartData1" [labels]="multiDoughnutChartLabels1"
                [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                [colors]="[{backgroundColor: ['#0087CE', '#ffffff'],hoverBackgroundColor: ['#0087CE', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
            </canvas>
            <div class="lable-panel-score">
                <div class="d-flex justify-content-center">
                    <div class="label-position-dnut-one">
                        <div class="num-cap">{{multiSectionScore[0].scores[0].score}}</div>
                        <div class="blue-underline">{{multiSectionScore[0].scores[0].label}}</div>
                        <div *ngIf='multiDoughnutScoreHigh[0] !="noScore"'>
                            <div *ngIf="multiDoughnutScoreHigh[0]"><img src="assets/img/icon_positive.svg" height="25"
                                    width="15" alt="">
                            </div>
                            <div *ngIf="!multiDoughnutScoreHigh[0]"><img src="assets/img/icon_negative.svg" height="25"
                                    width="15" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="label-position-dnut-two">
                        <div class="num-cap">{{multiSectionScore[0].scores[1].score}}</div>
                        <div class="grey-underline">{{multiSectionScore[0].scores[1].label}}</div>
                    </div>
                </div>
            </div>
        </div>
        <span class="divider"></span>
        <div class="col-md-6 text-center" *ngIf="multiDoughnutChartData2?.length>0">
            <div>
                <span class="sub-title-label">
                    {{multiSectionScore[1]?.behaviourType}}
                </span>
            </div>
            <canvas baseChart id="multi-doughnut-chart-normal-avg" width="100%" height="100%"
                [datasets]="multiDoughnutChartData2" [labels]="multiDoughnutChartLabels2"
                [chartType]="doughnutChartType" [options]="doughnutChartOptions"
                [colors]="[{backgroundColor: ['#35D44E', '#ffffff'],hoverBackgroundColor: ['#35D44E', '#ffffff']},{backgroundColor: ['#9B9B9B', '#ffffff'],hoverBackgroundColor: ['#9B9B9B', '#ffffff']}]">
            </canvas>
            <div class="lable-panel-score">
                <div class="d-flex justify-content-center">
                    <div class="label-position-dnut-one">
                        <div class="num-cap">{{multiSectionScore[1].scores[0].score}}</div>
                        <div class="green-underline">{{multiSectionScore[1].scores[0].label}} </div>
                        <div *ngIf='multiDoughnutScoreHigh[1] !="noScore"'>
                            <div *ngIf='multiDoughnutScoreHigh[1]'><img src="assets/img/icon_positive.svg" height="25"
                                    width="15" alt="">
                            </div>
                            <div *ngIf='!multiDoughnutScoreHigh[1]'><img src="assets/img/icon_negative.svg" height="25"
                                    width="15" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="label-position-dnut-two">
                        <div class="num-cap">{{multiSectionScore[1].scores[1].score}}</div>
                        <div class="grey-underline">{{multiSectionScore[1].scores[1].label}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>