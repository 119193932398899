<div class="filter-form">
    <form [formGroup]="dashboardfilterForm" (ngSubmit)="ondashboardConfirmClick()" #filterform="ngForm">
        <div class="market-place"
            *ngIf="dashboardName === 'MarketPlace Filter' || dashboardName === 'MarketPlace Gender Filter'  || dashboardName === 'MarketPlace Age Filter'">
            <div class="markettitleCls">
                <mat-form-field appearance="outline">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="customTitle" maxlength="50" required
                        (change)="onTitleChange($event)">
                    <span *ngIf="titleExists" class="titleErroCls">
                        Please enter different title
                    </span>
                </mat-form-field>
            </div>
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    *ngFor="let panel of filterList">
                    <mat-expansion-panel-header
                        *ngIf="panel.attributeID === 'Q203' || panel.attributeID === 'Q207' || panel.attributeID === 'Q212'">
                        <mat-panel-title>
                            {{panel.attributeName}}
                        </mat-panel-title>
                        <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngFor="let item of panel.filterValues">
                        <mat-checkbox [disabled]="fieldDisable" (change)="matcheckboxChange($event, item)"
                            [formControlName]="item?.filterId">
                            {{item?.attribute_Description}}</mat-checkbox>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div *ngIf="dashboardName === 'Internal Ad Filter' || dashboardName === 'Compare Demo Filter'" style="padding-top: 25px;">
            <div>
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Select Products
                        </mat-panel-title>
                        <mat-panel-description> </mat-panel-description>                     
                </mat-expansion-panel-header>
                <div *ngFor="let item of internalProductList">
                    <mat-checkbox (change)="onInternalProduct($event,item)" [checked]="item?.checked"
                        [value]="item?.data.productName">
                        {{item?.data.productName}}</mat-checkbox>
                </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            

                <!-- <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Products</mat-label>
                    <mat-select multiple [formControlName]="'product'" [attr.data-name]="'product'"
                        (selectionChange)="onInternalProduct($event)">
                        <mat-option *ngFor="let item of internalProductList" [value]="item?.productID">
                            {{item?.productName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Select Ad Name
                        </mat-panel-title>
                        <mat-panel-description> </mat-panel-description>                     
                </mat-expansion-panel-header>
                <div *ngFor="let item of internalAdsList">
                    <mat-checkbox (change)="onInternalAdName($event,item)"  [checked]="item?.checked"
                        [value]="item?.data?.adName">
                        {{item?.data?.adName}}</mat-checkbox>
                </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <!-- <mat-form-field class="w-100" appearance="outline">
                    <mat-label> Select Ad Name</mat-label>
                    <mat-select multiple [formControlName]="'ads'" [attr.data-name]="'ads'"
                        (selectionChange)="onInternalAdName($event)">
                        <mat-option *ngFor="let item of internalAdsList" [disabled]="adsDisable" [value]="item?.adID">
                            {{item?.adName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
        </div>
        <div *ngIf="dashboardName === 'DB1'|| dashboardName ==='DB3' || dashboardName ==='Ad Filter' || 
              dashboardName === 'MarketPlace Ad Filter' || dashboardName === 'Compare Ad Filter'
             || dashboardName === 'Norm Demo Ad Filter' ">
            <div class=" p-2 pl-3" *ngFor="let item of this.filterList">
                <div *ngIf="item?.filterType === 'SingleSelect'">
                    <label class="custom-label m-0">{{item?.attributeName}}</label>
                    <mat-radio-group aria-labelledby="Select an option" [formControlName]="item?.attributeID">
                        <mat-radio-button *ngFor="let norm of item?.filterValues"
                            (change)="onFormValueChange($event,item)" [value]="norm.filterId"
                            [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)">
                            {{norm.attribute_Description}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="item?.filterType === 'MultiSelect'">
                    <div *ngIf="item.attributeName !== 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="item.attributeName === 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1) || sampleTypeDisable"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="top-12" *ngIf="dashboardName==='Norm Filter' || dashboardName === 'Norm Demo Filter'">
            <div class="p-2 pl-3">
                <label class="custom-label custom-label-behaviour">Behaviors</label>
                <mat-form-field class="w-100 p-0 pt-2" height="45" style="padding: 7px; " appearance="outline">
                    <mat-select [formControlName]="'normbehaviour'" class="h-50" name="behavior">
                        <mat-option value="">All Behaviors</mat-option>
                        <mat-option *ngFor="let item of normBehaviourList" [value]="item?.attribute_Value">
                            {{item?.attribute_Description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" p-2 pl-3" *ngFor="let item of this.filterList">
                <div *ngIf="item?.filterType === 'SingleSelect'">
                    <label class="custom-label">{{item?.attributeName}}</label>
                    <mat-radio-group aria-labelledby="Select an option" [formControlName]="item?.attributeID">
                        <mat-radio-button *ngFor="let norm of item?.filterValues"
                            (change)="onFormValueChange($event,item)" [value]="norm.filterId"
                            [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)">
                            {{norm.attribute_Description}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="item?.filterType === 'MultiSelect'">
                    <div *ngIf="item.attributeName !== 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1)"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="item.attributeName === 'Sample Type'">
                        <mat-label>{{item.attributeName}}</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-select multiple [placeholder]="'Select' +' '+ item?.attributeName"
                                [formControlName]="item?.attributeID"
                                [disabled]="(fieldDisable && formSelectedItems.indexOf(item.attributeID) === -1) || sampleTypeDisable"
                                (selectionChange)="onFormValueChange($event,item)">
                                <mat-option *ngFor="let filter of item.filterValues" [value]="filter.filterId">
                                    {{filter.attribute_Description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between" *ngIf="dashboardName === 'DB1'|| dashboardName ==='DB3' || dashboardName ==='Ad Filter' || 
            dashboardName === 'Norm Filter' || dashboardName === 'MarketPlace Ad Filter' || dashboardName === 'Compare Ad Filter'
            || dashboardName === 'Norm Demo Ad Filter' || dashboardName === 'Norm Demo Filter'">
            <button mat-raised-button color="primary" id="confirmBtn" class="applyCls" tabindex="1">
                <span class="applyCls">Apply</span></button>
            <button mat-raised-button id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between"
            *ngIf="dashboardName === 'MarketPlace Filter' || dashboardName === 'MarketPlace Gender Filter'  || dashboardName === 'MarketPlace Age Filter'">
            <button mat-raised-button color="primary" id="confirmBtn" [ngClass]="{'applyCls' : this.customTitle.valid}"
                tabindex="1">
                <span [ngClass]="{'applyCls' : this.customTitle.valid}">Create</span></button>
            <button mat-raised-button type="reset" id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between"
            *ngIf="dashboardName === 'Internal Ad Filter' || dashboardName === 'Compare Demo Filter'">
            <button mat-raised-button color="primary" id="confirmBtn" class="applyCls" tabindex="1"
                [disabled]="!compareFlag">
                <span class="applyCls">Compare</span></button>
            <button mat-raised-button type="reset" id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
    </form>
    <div class="pl-3 pr-3" *ngIf="dashboardName === 'DB2'">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label>Sample Type</mat-label>
            <mat-select multiple [formControl]="filterSampleType">
                <mat-option *ngFor="let filter of filterSampleTypeList" [value]="filter.value">
                    {{filter.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="dashboardName === 'DB2'" class="dropdownButtonCls p-3 d-flex justify-content-between">
        <button mat-raised-button color="primary" id="confirmBtn" class="applyCls" tabindex="1">
            <span class="applyCls" (click)="onDemoApply()">Apply</span></button>
        <button mat-raised-button id="cancelBtn" (click)="onDemoClear()" tabindex="-1">Clear</button>
    </div>
</div>