import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExportService } from 'src/app/shared/export-service';
import { ApiService } from '../../../shared/api-service';

@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
})
export class FilterListComponent implements OnInit {
  @Input() filterType;
  filterSubscriber: Subscription;
  compareFilterSubscriber: Subscription;
  filterKeyList: any = [];
  filterList: any = {};
  constructor(private apiService: ApiService,private exportService:ExportService) {
    this.filterSubscriber = this.apiService.filterObserver.subscribe((value) => {
      this.exportService.clearHeader();
      this.filterList = value;
      this.filterKeyList = Object.keys(this.filterList);
      for(let key in this.filterKeyList){
        const dataD = this.filterKeyList[key]
        for(let element in this.filterList[dataD].valueName){
            this.exportService.setColumnHeader(this.filterList[dataD].valueName[element]);
        }
      }
    });
    this.compareFilterSubscriber = this.apiService.compareFilterObserver.subscribe((value) => {
      this.filterKeyList = value;
    });
  }

  ngOnInit(): void { }

  cancelList(index, totalValue, data) {
    totalValue.splice(index, 1);
    const value = totalValue;
    this.apiService.onFilterCancelDetails({ value, data });
  }

  cancelCompareList(list) {
    this.apiService.onCompareFilterCancelDetails(list);
  }

  ngOnDestroy() {
    this.filterList = {};
    this.filterKeyList = [];
    if (this.filterSubscriber) {
      this.filterSubscriber.unsubscribe();
    }
    if (this.compareFilterSubscriber) {
      this.compareFilterSubscriber.unsubscribe();
    }
  }
}
