<div class="filter-form">
    <form [formGroup]="dashboardfilterForm" (ngSubmit)="ondashboardConfirmClick()" #filterform="ngForm">
        <div class="market-place" *ngIf="dashboardName === 'MarketPlace Filter' || dashboardName === 'MarketPlace Gender Filter'  
        || dashboardName === 'MarketPlace Age Filter' || dashboardName === 'Video Library Filter'">
            <mat-form-field appearance="outline" *ngIf="dashboardName !== 'Video Library Filter'">
                <mat-label>Title</mat-label>
                <input matInput formControlName="customTitle" maxlength="50" required>
            </mat-form-field>
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    *ngFor="let panel of filterList">
                    <mat-expansion-panel-header
                        *ngIf="panel.attributeID === 'Q203' || panel.attributeID === 'Q207' || panel.attributeID === 'Q212' || panel.attributeID === 'Q201'">
                        <mat-panel-title>
                            {{panel.attributeName}}
                        </mat-panel-title>
                        <mat-panel-description> </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngFor="let item of panel.filterValues">
                        <mat-checkbox [disabled]="fieldDisable" (change)="matcheckboxChange($event, item)"
                            [formControlName]="item?.filterId">
                            {{item?.attribute_Description}}</mat-checkbox>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <ng-container *ngIf="dashboardName === 'Internal Ad Filter' || dashboardName === 'Compare Demo Filter'">
            <div class="mt-3 p-2 pl-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Products</mat-label>
                    <mat-select multiple [formControlName]="'product'" [attr.data-name]="'product'"
                        (selectionChange)="onInternalProduct($event)">
                        <mat-option *ngFor="let item of internalProductList" [value]="item?.productID">
                            {{item?.productName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="pr-2 pt-0 pl-3">
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label> Select Ad Name</mat-label>
                    <mat-select multiple [formControlName]="'ads'" [attr.data-name]="'ads'"
                        (selectionChange)="onInternalAdName($event)">
                        <mat-option *ngFor="let item of internalAdsList" [disabled]="adsDisable" [value]="item?.adID">
                            {{item?.adName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-container>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between"
            *ngIf="dashboardName === 'MarketPlace Filter' || dashboardName === 'MarketPlace Gender Filter'  || dashboardName === 'MarketPlace Age Filter' || dashboardName === 'Video Library Filter'">
            <button mat-raised-button color="primary" id="confirmBtn" [ngClass]="{'applyCls' : (this.customTitle.valid || (!this.customTitle.valid && this.dashboardName === 'Video Library Filter'))}"
                tabindex="1">
                <span [ngClass]="{'applyCls' : (this.customTitle.valid || (!this.customTitle.valid && this.dashboardName === 'Video Library Filter'))}">{{dashboardName === 'Video Library Filter' ?
                    'Apply' : 'Create'}}</span></button>
            <button mat-raised-button type="reset" id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
        <div class="dropdownButtonCls p-3 d-flex justify-content-between"
            *ngIf="dashboardName === 'Internal Ad Filter' || dashboardName === 'Compare Demo Filter'">
            <button mat-raised-button color="primary" id="confirmBtn" class="applyCls" tabindex="1"
                [disabled]="this.dashboardfilterForm.value?.ads?.length == 0">
                <span class="applyCls">Compare</span></button>
            <button mat-raised-button type="reset" id="cancelBtn" (click)="onClear()" tabindex="-1">Clear</button>
        </div>
    </form>
</div>